import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DialogReuse from '../pages/Editor/ReuseComponents/DialogReuse';
import { LoadingButton } from '@mui/lab';
import ResponseDialog from '../pages/Editor/ReuseComponents/ConfirmDialogReuse';

export default function CreateProjectDialog(props) {
    const { open, setOpen, setStatusUpdate } = props;
    const [projectName, setProjectName] = useState("");
    const [projectNameError, setProjectNameError] = useState(false);
    const [projectNameTextError, setProjectNameTextError] = useState("");
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [profile, setProfile] = useState();
    const [loading, setLoading] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const handleClose = () => {
        setProjectName("")
        setProjectNameError(false)
        setProjectNameTextError("")
        setDescription("")
        setDisabled(true)
        setOpen(false);
    };

    const handleValidation = async () => {
        setLoading(true);
        const validate = await handleValidate();
        if (validate) {
            const trim_project_name = projectName.trim();
            const id = uuidv4();
            const create_ts = Date.now();

            const requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    project_id: id,
                    project_name: trim_project_name,
                    creator_id: profile.uprofile.inum,
                    creator_name: profile.uprofile.displayName,
                    last_update_id: profile.uprofile.inum,
                    last_update_name: profile.uprofile.displayName,
                    create_timestamp: create_ts,
                    update_timestamp: create_ts,
                    description: description
                })
            };

            const response = await fetch(process.env.REACT_APP_API_URL + '/project/add-project', requestOptions);
            if (response.status === 200) {
                setLoading(false);
                setStatusUpdate(true);
                handleClose();
                setResponseStatus("Project has been created successfully");
                setOpenResponse(true);
            } else {
                console.error(response.status);
                setLoading(false);
                setResponseStatus(`Error status: ${response.status}`);
                setOpenResponse(true);
            }
        }

    };

    const handleConfirm = () => {
        handleValidation();
    };

    const handleDuplicate = async () => {
        const trim_project_name = projectName.trim();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_name: trim_project_name,
            })
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/project/check-duplicate-project', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            setDisabled(result.isDuplicate)
            if (result.isDuplicate) {
                setProjectNameError(true)
                setProjectNameTextError("The name already exists in the system.")
                return true;
            } else {
                setProjectNameError(false)
                setProjectNameTextError("")
                return false;
            }
        } else {
            console.error(response.status);
            setProjectNameError(true)
            setProjectNameTextError("Failed to retrieve data. Please try again later.")
            setDisabled(true)
            return true;
        }
    };

    const handleValidate = async () => {
        // ตรวจสอบว่าไม่มีการใส่ชื่อ
        if (!projectName) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name field cannot be empty.")
            return false;
        }

        // ตรวจสอบว่าไม่มีช่องว่าง
        if (/\s/.test(projectName)) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name should not contain spaces.")
            return false;
        }

        // ตรวจสอบว่ามีเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และเครื่องหมาย `_` หรือ `-`
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (!regex.test(projectName)) {
            setProjectNameError(true)
            setDisabled(true)
            setProjectNameTextError("The name should contain only English letters, numbers, underscores (_), or dashes (-).")
            return false;
        }

        const isDuplicate = await handleDuplicate();
        if (isDuplicate) { // ตรวจสอบว่าไม่มีชื่อซ้ำในระบบ
            return false;
        }

        setProjectNameError(false);
        setProjectNameTextError("");
        setDisabled(false);
        return true;
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    return (
        <Fragment>
            <DialogReuse
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                title={"Create New Project"}
                content={
                    <Box
                        component="form"
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="project-name"
                            label="Project Name"
                            fullWidth
                            margin="normal"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            onBlur={handleValidate}
                            error={projectNameError}
                            helperText={projectNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Box>
                }
                actions={
                    <LoadingButton variant="contained" disabled={disabled} loading={loading} onClick={() => handleConfirm()}>Create</LoadingButton>
                }
            />
            <ResponseDialog
                open={openResponse}
                maxWidth={"sm"}
                content={
                    <div>
                        <Typography variant="subtitle1">{responseStatus}</Typography>
                    </div>
                }
                actions={
                    <Button
                        variant="outlined"
                        onClick={() => onResponseClose()}
                    >
                        Close
                    </Button>
                }
            />
        </Fragment>
    );
}
