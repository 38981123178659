import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import DialogReuse from '../../ReuseComponents/DialogReuse';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';
import { setRegulatoryFile } from '../../../../redux/regulatoryFileSlice';

function CreateRegulatoryDialog(props) {

    const { open, setOpen, setStatusUpdate, setFirstCreate } = props;
    const dispatch = useDispatch();
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [regulatoryDiagramName, setRegulatoryDiagramName] = useState("");
    const [regulatoryDiagramNameError, setRegulatoryDiagramNameError] = useState(false);
    const [regulatoryDiagramNameTextError, setRegulatoryDiagramNameTextError] = useState("");
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    // const dpes = useSelector(getDpeRedux);
    const [dpes, setDpes] = useState([]);
    const [dpeSelected, setDpeSelected] = useState("");
    const [resources, setResources] = useState([]);
    const [resourceSelected, setResourceSelected] = useState("");
    const [disableCreate, setDisableCreate] = useState(true);
    const [disableResource, setDisableResource] = useState(true);

    const [profile, setProfile] = useState();
    const [project, setProject] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClose = () => {
        setRegulatoryDiagramName("");
        setRegulatoryDiagramNameError(false);
        setRegulatoryDiagramNameTextError("");
        setDescription("");
        setDisabled(true);
        setResources([]);
        setDpeSelected("");
        setResourceSelected("");
        setOpen(false);
    };

    const handleGetRegulatoryDiagram = async (regulatory_diagram_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatory/${regulatory_diagram_id}`);
        if (response.ok) {
            const result = await response.json();
            dispatch(setRegulatoryFile(result));
            localStorage.setItem('regulatory-file', JSON.stringify(result));
        } else {
            console.error(response.status);
        }
    };

    const getResourceAttributeList = async (initialNodes) => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected.resource_schema_endpoint}?resource=${resourceSelected}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    checked: false,
                };
            });

            const initial_attributes = initialNodes.map((node) => {
                if (node.type === 'root') {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                dpe: dpeSelected,
                                resource: resourceSelected,
                                attribute_selection: new_result
                            }
                        }
                    };
                }
                return node;
            });
            return initial_attributes;
        } else {
            console.error(response);
            return undefined;
        }
    };

    const handleCreate = async () => {

        setLoading(true);
        const validate = await handleValidate();
        if (validate) {

            // Add root node
            const initialNodes = [
                {
                    id: uuidv4(),
                    root: true,
                    type: 'subject',
                    position: { x: 0, y: 0 },
                    data: {
                        general_properties: {
                            label: 'All Allow',
                            description: '',
                            subject_constraint: []
                        },
                    },
                    deletable: false
                },
                {
                    id: uuidv4(),
                    root: true,
                    type: 'root',
                    position: { x: 300, y: 0 },
                    data: {
                        general_properties: {
                            label: 'Authorized Data',
                            description: '',
                        }
                    },
                    deletable: false
                },
            ];

            // Get Attribute from Resource
            const default_node = await getResourceAttributeList(initialNodes);

            if (default_node) {
                const trim_regulatory_name = regulatoryDiagramName.trim();
                const id = uuidv4();
                const create_ts = Date.now();

                const requestOptions = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        regulatory_diagram_id: id,
                        regulatory_diagram_name: trim_regulatory_name,
                        owner_id: profile.uprofile.inum,
                        owner: profile.uprofile.displayName,
                        updater_id: profile.uprofile.inum,
                        updater: profile.uprofile.displayName,
                        created_at: create_ts,
                        updater_at: create_ts,
                        description: description,
                        validated: true,
                        diagram: {
                            nodes: default_node,
                            edges: []
                        }
                    })
                };

                const response = await fetch(process.env.REACT_APP_API_URL + '/regulatory-diagram/add-regulatory/' + project.project.project_id, requestOptions);
                if (response.status === 200) {
                    setLoading(false);
                    setStatusUpdate(true);
                    handleClose();
                    handleGetRegulatoryDiagram(id);
                    setFirstCreate(true);
                } else {
                    console.error(response.status);
                    setLoading(false);
                    setFirstCreate(false);
                    setResponseStatus(`Error status: ${response.status}`);
                    setOpenResponse(true);
                }
            }


        }

    };

    const handleDuplicate = async () => {
        const trim_regulatory_name = regulatoryDiagramName.trim();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_id: project.project.project_id,
                regulatory_diagram_name: trim_regulatory_name
            })
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/regulatory-diagram/check-duplicate-regulatory', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            setDisabled(result.isDuplicate)
            if (result.isDuplicate) {
                setRegulatoryDiagramNameError(true)
                setRegulatoryDiagramNameTextError("The name already exists in the system.")
                return true;
            } else {
                setRegulatoryDiagramNameError(false)
                setRegulatoryDiagramNameTextError("")
                return false;
            }
        } else {
            console.error(response.status);
            setRegulatoryDiagramNameError(true)
            setRegulatoryDiagramNameTextError("Failed to retrieve data. Please try again later.")
            setDisabled(true)
            return true;
        }
    };

    const handleValidate = async () => {
        // ตรวจสอบว่าไม่มีการใส่ชื่อ
        if (!regulatoryDiagramName) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name field cannot be empty.")
            return false;
        }

        // ตรวจสอบว่าไม่มีช่องว่าง
        if (/\s/.test(regulatoryDiagramName)) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name should not contain spaces.")
            return false;
        }

        // ตรวจสอบว่ามีเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และเครื่องหมาย `_` หรือ `-`
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (!regex.test(regulatoryDiagramName)) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name should contain only English letters, numbers, underscores (_), or dashes (-).")
            return false;
        }

        const isDuplicate = await handleDuplicate();
        if (isDuplicate) { // ตรวจสอบว่าไม่มีชื่อซ้ำในระบบ
            return false;
        }

        setRegulatoryDiagramNameError(false);
        setRegulatoryDiagramNameTextError("");
        setDisabled(false);
        return true;
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const getDpeList = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/get-dpes/${project.project.project_id}`);
        if (response.ok) {
            let DPEs = []
            const dicovery = await response.json();
            for (const dLink of dicovery) {
                const dpe_resp = await fetch(dLink.dpe_discovery_url);
                if (dpe_resp.ok) {
                    const dpe = await dpe_resp.json();
                    DPEs.push({
                        ...dLink,
                        ...dpe
                    })
                }
                else {
                    DPEs.push({
                        ...dLink,
                        resources_name: dLink.dpe_name,
                        resource_type: "DB_Null",
                        resource_connection: {
                            status: "Error",
                            message: "connection refused!"
                        }
                    })
                }
            }
            setDpes(DPEs);
        }
    };

    const getResourceList = async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected.resources_endpoint}`, option);
        if (response.ok) {
            const result = await response.json();
            setResources(result);
        } else {
            console.error(response);
        }
    };

    const handleChangeDPE = useCallback((newChange) => {
        if (dpeSelected?.resources_name !== newChange.resources_name) {
            setResourceSelected("");
        }
        setDpeSelected(newChange);
    }, [dpeSelected]);

    useEffect(() => {
        if (project && open) {
            getDpeList();
        }
    }, [project, open]);

    useEffect(() => {
        if (dpeSelected) {
            setDisableResource(false);
            getResourceList();
        } else {
            setDisableResource(true);
        }
    }, [dpeSelected]);

    useEffect(() => {
        if (dpeSelected && resourceSelected && !disabled) {
            setDisableCreate(false);
        } else {
            setDisableCreate(true);
        }
    }, [dpeSelected, resourceSelected, disabled])

    return (
        <Fragment>
            <DialogReuse
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                title={"Create Regulatory"}
                content={
                    <Box
                        component="form"
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="regulatory-name"
                            label="Regulatory Name"
                            fullWidth
                            margin="normal"
                            value={regulatoryDiagramName}
                            onChange={(e) => setRegulatoryDiagramName(e.target.value)}
                            onBlur={handleValidate}
                            error={regulatoryDiagramNameError}
                            helperText={regulatoryDiagramNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <TextField
                            id="data-proxy-engine"
                            fullWidth
                            select
                            margin="normal"
                            label="Data Proxy Engine"
                            defaultValue="-"
                            value={dpeSelected}
                            onChange={(e) => {
                                handleChangeDPE(e.target.value);
                            }}
                        >
                            {dpes.map((option) => (
                                <MenuItem key={option.dpe_id} value={option}>
                                    {option.resources_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="resource"
                            fullWidth
                            select
                            margin="normal"
                            label="Resource"
                            disabled={disableResource}
                            defaultValue="-"
                            value={resourceSelected}
                            onChange={(e) => {
                                setResourceSelected(e.target.value);
                            }}
                        >
                            {resources.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                }
                actions={
                    <LoadingButton variant="contained" disabled={disableCreate} loading={loading} onClick={() => handleCreate()}>Create</LoadingButton>
                }
            />
            <ResponseDialog
                open={openResponse}
                maxWidth={"sm"}
                content={
                    <div>
                        <Typography variant="subtitle1">{responseStatus}</Typography>
                    </div>
                }
                actions={
                    <Button
                        variant="outlined"
                        onClick={() => onResponseClose()}
                    >
                        Close
                    </Button>
                }
            />
        </Fragment>
    )
}

export default CreateRegulatoryDialog