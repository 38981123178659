import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SvgIcon, useTheme } from '@mui/material';
import { Outlet, useLocation, Link, Routes, Route, Navigate } from 'react-router-dom';
import EditorPageToolBar from '../components/EditorPageToolBar';
import EditorPage from './Editor/EditorPage';
import SimulatorPage from './Simulator/SimulatorPage';
import DeployerPage from './Deployer/DeployerPage';
import DRGPageToolBar from '../components/DRGPageToolBar';

const SvgIconEditor = (props) => {
  return (
    <SvgIcon sx={{ width: 35, height: 35, margin: 1 }}>
      <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={(props.value === "/editor") ? props.color : "gray"}>
        <path d="M3 0C1.338 0 0 1.338 0 3v6.107h2.858c1.092 0 1.97.868 1.964 1.96v.021c.812-.095 1.312-.352 1.674-.683.416-.382.69-.91 1.016-1.499.325-.59.71-1.244 1.408-1.723.575-.395 1.355-.644 2.384-.686v-.45c0-1.092.88-1.976 1.972-1.976h7.893c1.091 0 1.974.884 1.974 1.976v1.942c0 1.091-.883 2.029-1.974 2.029h-7.893c-1.092 0-1.972-.938-1.972-2.03v-.453c-.853.037-1.408.236-1.798.504-.48.33-.774.802-1.086 1.368-.312.565-.63 1.22-1.222 1.763l-.077.069c3.071.415 4.465 1.555 5.651 2.593 1.39 1.215 2.476 2.275 6.3 2.288v-.46c0-1.092.894-1.946 1.986-1.946H24V3c0-1.662-1.338-3-3-3zm10.276 5.41c-.369 0-.687.268-.687.637v1.942c0 .368.318.636.687.636h7.892a.614.614 0 0 0 .635-.636V6.047a.614.614 0 0 0-.635-.636zM0 10.448v3.267h2.858a.696.696 0 0 0 .678-.69v-1.942c0-.368-.31-.635-.678-.635zm4.821 1.67v.907A1.965 1.965 0 0 1 2.858 15H0v6c0 1.662 1.338 3 3 3h18c1.662 0 3-1.338 3-3v-1.393h-2.942c-1.092 0-1.986-.913-1.986-2.005v-.445c-4.046-.032-5.598-1.333-6.983-2.544-1.437-1.257-2.751-2.431-7.268-2.496zM21.058 15a.644.644 0 0 0-.647.66v1.942c0 .368.278.612.647.612H24V15z" /></svg>
    </SvgIcon>
  );
}

const SimulatorIcon = (props) => {
  return (
    <SvgIcon sx={{ width: 35, height: 35, margin: 1 }}>
      <svg version="1.1" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg" fill={(props.value === "/simulator") ? props.color : "gray"}>
        <path transform="translate(153)" d="m0 0h1742l16 4 19 6 13 5 22 12 16 12 11 9v2l4 2 2 4h2l9 11 9 12 8 13 10 21 6 18 4 16h2v1053l-2046 1-2-1v-1048l2-6 6-23 10-24 8-14 11-16 10-11 7-8 11-10 18-13 16-9 16-7 19-6 17-4zm1466 180-23 2-25 6-17 6-16 8-14 8-16 13-8 7-12 12-12 16-10 16-9 19-6 18-4 17-3 29v11l-9 3-41 16-29 12-31 12-29 12-31 12-60 24-17 7-2-4-12-14-11-11-11-9-15-11-15-9-15-8-23-9-22-6-17-3-24-2h-12l-25 2-25 5-27 9-25 12-17 11-14 11-10 9-8 7-11 12-13 17-11 18-8 16-12 30-219 1-3-9-8-17-11-18-8-10-9-10-13-13-16-12-15-9-16-8-20-7-16-4-15-2-14-1h-10l-23 2-19 4-21 7-17 8-16 10-14 11-10 9-11 11-10 13-11 17-10 21-6 18-4 17-2 16v33l4 24 4 15 9 22 7 13 7 11 10 13 11 12 7 7 14 11 15 10 16 8 20 8 25 6 17 2h28l20-3 23-6 19-8 17-9 17-12 10-9 8-7 10-11 13-18 8-14 8-18 1-3 2-1h218l3 11 8 20 8 16 10 16 8 11 11 13 20 20 13 10 15 10 16 9 15 7 16 6 19 5 24 4 10 1h32l22-3 25-6 17-6 16-7 18-10 16-11 17-14 19-19 6-8 5 1 21 8 220 88 3 32 3 17 4 15 9 22 11 20 9 12 12 14 10 10 17 13 14 9 23 11 18 6 18 4 16 2h27l22-3 23-6 19-8 17-9 17-12 15-13 13-14 13-18 8-14 9-21 6-20 3-17 1-10v-27l-3-22-4-16-6-18-9-19-11-17-11-14-17-17-17-13-15-9-21-10-19-6-21-4-8-1h-35l-20 3-22 6-19 8-16 9-14 10-13 11-12 12-6-1-31-12-27-11-145-58-37-15-1-1v-45l29-12 205-82 8-3h6l7 8 12 11 17 12 14 8 12 6 16 6 20 5 22 3h26l22-3 19-5 19-7 21-11 17-12 10-9 8-7 10-11 13-18 8-14 9-21 6-20 3-17 1-10v-27l-3-22-4-16-6-18-9-19-11-17-11-14-18-18-16-12-15-9-16-8-20-7-16-4-16-2z" />
        <path transform="translate(0,1319)" d="m0 0h2048v92h-2l-5 21-6 17-9 19-7 11-10 14-12 13-5 5-12 11-18 12-14 8-19 8-17 5-22 4-12 1h-1709l-20-2-24-6-16-6-16-8-12-7-12-9-11-9-14-14-8-10-9-13-9-16-8-18-6-19-4-16z" />
        <path transform="translate(785,1679)" d="m0 0h471l8 1 1 5-1 122h63l20 2 15 4 17 7 10 6 12 9 10 9 9 10 10 16 8 17 4 14 2 14v82l-4 14-6 11-8 10-13 9-16 7h-747l-15-7-10-7-9-9-8-13-4-13-1-11v-64l2-18 5-19 7-16 9-14 9-11 11-11 14-10 17-9 15-5 16-3 11-1h64v-126z" />
        <path transform="translate(1019,499)" d="m0 0h10l17 2 18 6 13 7 12 9 9 8 8 10 8 13 6 15 4 17v28l-4 17-5 12-8 14-6 8-6 7-11 9-13 8-14 6-14 4-7 1h-26l-17-4-16-7-10-6-10-8-11-11-7-10-6-10-6-16-3-13-1-9v-10l2-17 5-16 8-16 8-11 9-10 11-9 14-8 14-6 14-3z" />
        <path transform="translate(1621,299)" d="m0 0 14 1 13 4 12 7 9 8 9 13 5 13 1 5v21l-5 15-7 11-6 7-11 8-11 5-12 3h-18l-16-5-11-7-9-8-9-14-5-14-1-16 3-15 8-16 9-10 9-7 10-5 10-3z" />
        <path transform="translate(1621,779)" d="m0 0 14 1 13 4 12 7 9 8 9 13 5 13 1 5v21l-5 15-7 11-6 7-11 8-11 5-12 3h-18l-16-5-11-7-9-8-9-14-5-14-1-16 3-15 8-16 9-10 9-7 10-5 10-3z" />
        <path transform="translate(421,539)" d="m0 0 14 1 13 4 12 7 9 8 9 13 5 13 1 5v21l-5 15-7 11-6 7-11 8-11 5-12 3h-18l-16-5-11-7-9-8-9-14-5-14-1-16 3-15 8-16 9-10 9-7 10-5 10-3z" />
        <path transform="translate(1897)" d="m0 0 2 1z" />
        <path transform="translate(647,2047)" d="m0 0" />
      </svg>
    </SvgIcon>
  )
}

const SvgIconDeployer = (props) => {
  return (
    <SvgIcon sx={{ width: 35, height: 35, margin: 1 }}>
      <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill={(props.value === "/deployer") ? props.color : "gray"}>
        <path d="M16.01 10.71a.364.364 0 01-.343-.006l-.558-.331a.43.43 0 01-.182-.312l-.014-.65a.363.363 0 01.165-.3l6.7-3.902L12.377.085A.799.799 0 0012 0a.798.798 0 00-.377.085l-9.4 5.124 10.53 6.13c.098.054.172.181.172.295v8.944c0 .112-.08.241-.178.294l-.567.315c-.171.062-.256.043-.361 0l-.569-.315a.362.362 0 01-.175-.294v-7.973a.223.223 0 00-.095-.156L1.702 7.048v10.579c0 .236.167.528.371.648l9.556 5.636c.102.06.237.09.371.089a.745.745 0 00.371-.09l9.557-5.635a.835.835 0 00.37-.648V7.047Z" /></svg>
    </SvgIcon>
  );
}

export default function MainLayout() {
  const theme = useTheme();
  const location = useLocation();
  const currentTab = location.pathname === '/' ? '/editor' : '/' + location.pathname.split('/')[1];
  const hideTabs = location.pathname === '/editor/regulatory';

  return (
    <Box sx={{ overflow: 'hidden', bgcolor: '#f3f3f3' }} >
      {!hideTabs ? <EditorPageToolBar /> : <DRGPageToolBar />}
      <div className='flex mt-[52px] h-[calc(100vh-52px)]'>
        {!hideTabs &&
          <Tabs
            orientation="vertical"
            value={currentTab}
            aria-label="Vertical tabs"
            sx={{ borderRight: 1, borderColor: 'divider', bgcolor: 'background.paper', minWidth: 100 }}
          >
            <Tab
              label="Editor"
              value="/editor"
              component={Link}
              to="/editor"
              icon={<SvgIconEditor value={currentTab} color={theme.palette.primary.main} />}
              sx={{
                marginX: 0,
                paddingY: 2,
                boxShadow: (currentTab === "/editor" && 3),
                textTransform: 'none',
              }}
            />
            <Tab
              label="Simulator"
              value="/simulator"
              component={Link}
              to="/simulator"
              icon={<SimulatorIcon value={currentTab} color={theme.palette.primary.main} />}
              sx={{
                marginX: 0,
                paddingY: 2,
                boxShadow: (currentTab === "/simulator" && 3),
                textTransform: 'none'
              }}
            />
            <Tab
              label="Deployer"
              value="/deployer"
              component={Link}
              to="/deployer"
              icon={<SvgIconDeployer value={currentTab} color={theme.palette.primary.main} />}
              sx={{
                marginX: 0,
                paddingY: 2,
                boxShadow: (currentTab === "/deployer" && 3),
                textTransform: 'none'
              }}
            />
          </Tabs>
        }
        <Routes>
          <Route path="editor/*" element={<EditorPage />} />
          <Route path="simulator/*" element={<SimulatorPage />} />
          <Route path="deployer/*" element={<DeployerPage />} />
          <Route path="/" element={<Navigate to="/editor/projects" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Box>

  );

}