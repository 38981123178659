import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
    reconnectEdge,
    ConnectionMode,
    useNodesData,
    useHandleConnections,
    useConnection,
    BackgroundVariant,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, Box, Button, CircularProgress, Drawer, Tooltip, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RootNode from './RootNode';
import SelectResourceDialog from './SelectResourceDialog';
import ConditionNode from './ConditionNode';
import PrivacyNode from './PrivacyNode';
import ConjunctionNode from './ConjunctionNode';
import CreateConditionDialog from './CreateConditionDialog';
import AttributeSelection from './AttributeSelection';
import SelectionNode from './SelectionNode';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';
import { LoadingButton } from '@mui/lab';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import TestSelectionNode from './TestSelectionNode';
import TestConditionNode from './TestConditionNode';
import TestConjunctionNode from './TestConjunctionNode';
import TestPrivacyNode from './TestPrivacyNode';
import { getOpenRightSide, setOpenRightSide } from '../../../../redux/openRightSideSlice';
import ConfirmDialogReuse from '../../ReuseComponents/ConfirmDialogReuse';
import { clearSelectedNodeRedux, getSelectedNodeRedux, setSelectedNodeRedux } from '../../../../redux/selectedNodeSlice';
import { getRegulatoryFile } from '../../../../redux/regulatoryFileSlice';
import TestSubjectNode from './TestSubjectNode';
import RegulatoryRightSide from './RegulatoryRightSide';
import dagre from 'dagre';
import DialogReuse from '../../ReuseComponents/DialogReuse';
import CreateSubjectConstraintDialog from '../../DRG/Subject/components/CreateSubjectConstraintDialog';

const nodeTypes = {
    root: RootNode,
    // condition: ConditionNode,
    // selection: SelectionNode,
    // privacy: PrivacyNode,
    // conjunction: ConjunctionNode,
    condition: TestConditionNode,
    selection: TestSelectionNode,
    conjunction: TestConjunctionNode,
    privacy: TestPrivacyNode,
    subject: TestSubjectNode
};

const nodeWidth = 70;
const nodeHeight = 100;

const getLayoutedElements = (nodes, edges) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        if (node.type === 'subject') {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        }
    });

    return { nodes, edges };
};

function RegulatoryDiagram(props) {

    const { nodes, setNodes, edges, setEdges, firstCreate, setFirstCreate } = props;
    const dispatch = useDispatch();
    const openRightSide = useSelector(getOpenRightSide);
    const selectedNode = useSelector(getSelectedNodeRedux);
    const [openProperties, setOpenProperties] = useState(false);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [openSelectResource, setOpenSelectResource] = useState(false);
    const [openCreateCondition, setOpenCreateCondition] = useState(false);
    const [openAttributeSelection, setOpenAttributeSelection] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSave, setOpenSave] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [showMinimap, setShowMinimap] = useState(false);
    const [hoveredEdgeId, setHoveredEdgeId] = useState(null);

    const [updateProperties, setUpdateProperties] = useState(false);

    const [openResourceUpdate, setOpenResourceUpdate] = useState(false);
    const [resourceUpdateDetail, setResourceUpdateDetail] = useState();

    const [openNotificationDpe, setOpenNotificationDpe] = useState(false);
    const [notificationDpeDetail, setNotificationDpeDetail] = useState();

    const isReconnectRef = useRef(false);

    const [notificationDpeEvent, setNotificationDpeEvent] = useState("");

    const [openCreateSubjectConstraint, setOpenCreateSubjectConstraint] = useState(false);

    const {
        zoomTo,
        getViewport,
        getNodes,
        getEdges,
        setViewport,
        fitView,
        screenToFlowPosition,
        getNode,
    } = useReactFlow();

    const drawerWidth = 300 + 8;

    const [profile, setProfile] = useState();
    const [project, setProject] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        allFlows[currentFile.regulatory_diagram_id] = viewport_flow;
        localStorage.setItem('regulatory-diagram', JSON.stringify(allFlows));
    }, [getViewport, getNodes, getEdges]);

    // ฟังก์ชันตรวจสอบความแตกต่างระหว่างสองอาร์เรย์
    const compareArrays = (oldArray, newArray) => {
        const oldMap = new Map(oldArray.map(item => [item.name, item]));
        const newMap = new Map(newArray.map(item => [item.name, item]));

        const added = [];
        const removed = [];
        const modified = [];

        for (const [name, newItem] of newMap) {
            const oldItem = oldMap.get(name);
            if (!oldItem) {
                added.push(newItem);
            } else if (oldItem.data_type !== newItem.data_type) {
                modified.push({
                    name,
                    oldDataType: oldItem.data_type,
                    newDataType: newItem.data_type
                });
            }
        }

        for (const [name, oldItem] of oldMap) {
            if (!newMap.has(name)) {
                removed.push(oldItem);
            }
        }

        return {
            added,
            removed,
            modified,
            summary: {
                addedCount: added.length,
                removedCount: removed.length,
                modifiedCount: modified.length
            }
        };
    };

    const updateData = (oldAttr, newAttr) => {
        const result = compareArrays(oldAttr, newAttr);

        // console.log('Added:', result.added);
        // console.log('Removed:', result.removed);
        // console.log('Modified:', result.modified);
        // console.log('Summary:', result.summary);

        if (result.added.length > 0 || result.removed.length > 0 || result.modified.length > 0) {
            setResourceUpdateDetail(result);
            setOpenResourceUpdate(true);
        }

        // อัปเดต oldArray ด้วยข้อมูลใหม่
        // oldArray = [...newArray];
    };

    const handleUpdateResourceAttribute = async (root_node, node_data) => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const resource_schema_endpoint = root_node?.data?.general_properties?.dpe?.resource_schema_endpoint;
        const resource = root_node?.data?.general_properties?.resource;

        const response = await fetch(`${resource_schema_endpoint}?resource=${resource}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    checked: false
                };
            });
            updateData(root_node.data.general_properties.attribute_selection, new_result); // Test 
            const initial_attributes = node_data.map((node) => {
                if (node.type === 'root') {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                attribute_selection: new_result
                            }
                        }
                    };
                }
                return node;
            });
            return initial_attributes;
        } else {
            console.error(response);
            return [];
        }
    };

    const validateDPEInProject = async (rootNode) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/get-dpes/${project?.project?.project_id}`);
        if (response.ok) {
            const result = await response.json();
            const findDpe = result.find(dpe => dpe.dpe_id === rootNode?.data?.general_properties?.dpe?.dpe_id);
            return findDpe;
        } else {
            console.error(response);
            return undefined;
        }
    };

    const handleValidateDPE = async (node_data) => {
        const root_node = node_data.find(node => node.type === 'root');
        const hasDPE = await validateDPEInProject(root_node);

        if (!hasDPE) { // ไม่มี DPE ที่ใช้งานกับ Diagram อยู่ใน Project
            const msg = `No DPE "${root_node?.data?.general_properties?.dpe?.resources_name}" found in this project.`;
            const root_node_update = {
                ...root_node,
                data: {
                    ...root_node.data,
                    error_dpe: true,
                    error_dpe_text: ["The requested DPE data is not available."]
                }
            };
            const nodes_update = node_data.map((node) => {
                if (node.type === 'root') {
                    return root_node_update;
                }
                return node;
            });
            setNotificationDpeDetail(msg);
            setNotificationDpeEvent("dpe_id");
            setOpenNotificationDpe(true);
            return nodes_update;
        } else {
            if (hasDPE?.dpe_discovery_url !== root_node?.data?.general_properties?.dpe?.dpe_discovery_url) {  // ตรวจสอบ DPE มีการเปลี่ยนแปลง dpe_discovery_url หรือไม่
                const msg = `The DPE host has been changed. Please select the DPE data again.`; // แจ้งให้ user เปลี่ยน genaral properties (Resource)
                setNotificationDpeDetail(msg);
                setNotificationDpeEvent("dpe_discovery_url");
                setOpenNotificationDpe(true);
                return node_data;
            } else {
                return handleUpdateResourceAttribute(root_node, node_data);
            }
        }

    };

    const fetchResources = async (general_properties) => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${general_properties?.dpe?.resources_endpoint}`, option);
        if (response.ok) {
            const result = await response.json();
            const findResource = result.find(resource => resource === general_properties?.resource);
            return findResource;
        } else {
            console.error(response);
            return undefined;
        }
    };

    const handleValidateResource = async (node_data) => {
        const root_node = node_data.find(node => node.type === 'root');
        const hasResource = await fetchResources(root_node?.data?.general_properties);
        if (!hasResource) {
            const msg = `The requested resource is not available.`;
            const root_node_update = {
                ...root_node,
                data: {
                    ...root_node.data,
                    error_resource: true,
                    error_resource_text: ["The requested resource is not available."]
                }
            };
            const nodes_update = node_data.map((node) => {
                if (node.type === 'root') {
                    return root_node_update;
                }
                return node;
            });
            setNotificationDpeDetail(msg);
            setNotificationDpeEvent("dpe_id");
            setOpenNotificationDpe(true);
            return nodes_update;
        } else {
            return node_data;
        }
    };

    const restoreFlowState = async (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('regulatory-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('regulatory-file')) || {};
        const flowState = allFlows[currentFile.regulatory_diagram_id];
        const validateDPE = await handleValidateDPE(node_data);
        const initial_attributes = await handleValidateResource(validateDPE);
        setTimeout(() => {
            setNodes(initial_attributes);
            setEdges(edge_data);
        }, 300);
        if (flowState) {
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (regulatoryFile) {
            if (regulatoryFile?.diagram) {
                setLoading(true);
                restoreFlowState(regulatoryFile.diagram.nodes, regulatoryFile.diagram.edges);
            }
        }
    }, [regulatoryFile])

    useEffect(() => {
        if (firstCreate && nodes.length > 0) {
            dispatch(setOpenRightSide(true));
            setNodes((nds) =>
                nds.map((node, index) => ({ ...node, selected: node.type === 'root' }))
            );
            const root_nodes = nodes.find((node) => node.type === 'root');
            dispatch(setSelectedNodeRedux(root_nodes));
            setOpenProperties(true);
        }
    }, [firstCreate, nodes])

    const onPaneClick = () => {
        setNodes(nodes => nodes.map((node) => ({
            ...node,
            data: { ...node.data, showHandle: false }
        })));
        setFirstCreate(false);
        setOpenProperties(false);
        dispatch(clearSelectedNodeRedux());
    };

    const findPaths = useCallback((nodeId) => {
        const outgoingEdges = edges.filter((e) => e.target === nodeId);
        // console.log('outgoingEdges:', outgoingEdges);
    }, [nodes]);

    const onNodeClick = (event, node) => {
        console.log("node: ", node);
        setFirstCreate(false);
        dispatch(setSelectedNodeRedux(node));
        setOpenProperties(true);
        findPaths(node.id);
    };

    const onEdgeMouseEnter = (event, edge) => {
        setHoveredEdgeId(edge.id);
    };

    const onEdgeMouseLeave = () => {
        setHoveredEdgeId(null);
    };

    const edgesWithUpdatedData = useMemo(() => {
        return edges.map((edge) => {
            if (edge?.markerEnd?.color === 'red') {
                return {
                    ...edge,
                    style: {
                        ...edge.style,
                        stroke: edge.selected === true ? '#1976d2' : edge?.markerEnd?.color,
                        strokeWidth: hoveredEdgeId === edge.id ? 1.5 : 1, // เพิ่มความหนาของเส้นเมื่อ hover หรือเลือก
                    },
                    markerEnd: {
                        ...edge.markerEnd,
                        color: edge.selected === true ? '#1976d2' : edge?.markerEnd?.color,
                    },
                };
            } else {
                return {
                    ...edge,
                    style: {
                        ...edge.style,
                        stroke: edge.selected === true ? '#1976d2' : edge?.markerEnd?.color,
                        strokeWidth: hoveredEdgeId === edge.id ? 1.5 : 1, // เพิ่มความหนาของเส้นเมื่อ hover หรือเลือก
                    },
                    markerEnd: {
                        ...edge.markerEnd,
                        color: edge.selected === true ? '#1976d2' : edge?.markerEnd?.color,
                    },
                };
            }
        });
    }, [edges, hoveredEdgeId]);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const type = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            let newNode = {};

            const root_node = nodes.find((n) => n.type === 'root');

            if (type === 'condition') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type}`,
                            description: "",
                            conditions: [],
                            attribute_selection: root_node?.data?.general_properties?.attribute_selection
                        },
                        warning: true,
                        warning_text: ["Connection to subject and authorized data is missing."],
                        error: false,
                        error_text: [],
                    },
                };
            } else if (type === 'selection') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type}`,
                            description: "",
                            attribute_selection: root_node?.data?.general_properties?.attribute_selection
                        },
                        warning: true,
                        warning_text: ["Connection to subject and authorized data is missing."],
                        error: false,
                        error_text: [],
                    },
                };
            } else if (type === 'privacy') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type}`,
                            description: "",
                            attribute_selection: root_node?.data?.general_properties?.attribute_selection,
                            privacies: []
                        },
                        warning: true,
                        warning_text: ["Connection to subject and authorized data is missing."],
                        error: false,
                        error_text: [],
                    },
                };
            } else if (type === 'conjunction') {
                newNode = {
                    id: uuidv4(),
                    type: type,
                    position,
                    data: {
                        general_properties: {
                            label: `${type}`,
                            description: "",
                            attribute_selection: root_node?.data?.general_properties?.attribute_selection
                        },
                        warning: true,
                        warning_text: ["Connection to subject and authorized data is missing."],
                        error: false,
                        error_text: [],
                    },
                };
            }

            setNodes((nds) => nds.concat(newNode));
        },
        [screenToFlowPosition, nodes, setNodes],
    );

    const onAddNode = useCallback((parentNode) => {
        const newNodeId = uuidv4();
        const newNode = {
            id: newNodeId,
            type: 'subject',
            position: { x: parentNode.position.x, y: parentNode.position.y + 100 },
            data: {
                onAddNode: () => onAddNode(newNode),
                general_properties: {
                    label: 'subject',
                    description: '',
                    subject_constraint: []
                }
            }
        };

        const newEdge = {
            id: `${parentNode.id}-${newNodeId}`,
            source: parentNode.id,
            sourceHandle: "bottom-source",
            target: newNodeId,
            targetHandle: "top-target",
            style: { stroke: 'rgba(0,0,0,0.7)', strokeDasharray: '5,5' }, // เส้นประ
            // markerEnd: {
            //     type: MarkerType.ArrowClosed,
            //     width: 20,
            //     height: 20,
            // },
        };

        const newNodes = [...nodes, newNode];
        const newEdges = [...edges, newEdge];

        const layoutedElements = getLayoutedElements(newNodes, newEdges);
        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);

    }, [nodes, edges]);

    const handleSaveDiagram = () => {
        setOpenSave(true);
    };

    const shouldUpdateNode = (oldObject, newObject) => {
        return JSON.stringify(oldObject) !== JSON.stringify(newObject);
    };

    useEffect(() => {
        if (nodes.length !== 0) {
            nodes.map((node) => {
                if (node.id === selectedNode?.id) {
                    if (shouldUpdateNode(node, selectedNode)) {
                        let update_current_node = {
                            ...node,
                            data: {
                                ...node.data,
                            },
                        };
                        dispatch(setSelectedNodeRedux(update_current_node)); // Test
                    }
                }
            });
        }
    }, [nodes, selectedNode])

    const onNodesChange = useCallback(
        async (changes) => {
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node?.measured?.width || 150,
                            height: node?.measured?.height || 40,
                        }
                    })
                );
                return applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    const checkAttributeSelections = (object_attr) => {
        const hasChecked = object_attr.some(item => item.checked);
        if (hasChecked) {
            return true;
        } else {
            return false;
        }
    };

    const handleCheckInheritData = async (targetNode, sourceNode) => {

        if (targetNode?.data?.general_properties?.attribute_selection === undefined || sourceNode?.data?.general_properties?.attribute_selection === undefined) {
            return nodes;
        }
        const target_attributes = checkAttributeSelections(targetNode?.data?.general_properties?.attribute_selection);
        const source_attributes = checkAttributeSelections(sourceNode?.data?.general_properties?.attribute_selection);

        if (target_attributes && !source_attributes && targetNode.type !== 'root') { // target มีค่า checked ที่เป็น true อยู่ และ source มีค่า checked = false ทั้งหมด
            const updatedStatusNodes = nodes.map((node) => {
                if (node.id === sourceNode.id) {
                    const inhertitAttributes = targetNode?.data?.general_properties?.attribute_selection.map((attr) => (
                        {
                            ...attr,
                            checked: attr.checked
                        }
                    ))
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                attribute_selection: inhertitAttributes
                            }
                        }
                    };
                }
                return node;
            });
            return updatedStatusNodes;
        } else if (!target_attributes && source_attributes && targetNode.type !== 'root') { // target มีค่า checked = false ทั้งหมด และ source มีค่า checked ที่เป็น true อยู่
            const updatedStatusNodes = nodes.map((node) => {
                if (node.id === targetNode.id) {
                    const inhertitAttributes = sourceNode?.data?.general_properties?.attribute_selection.map((attr) => (
                        {
                            ...attr,
                            checked: attr.checked
                        }
                    ))
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                attribute_selection: inhertitAttributes
                            }
                        }
                    };
                }
                return node;
            });
            return updatedStatusNodes;
        } else {
            return nodes; // ไม่ทำการสืบทอดใด ๆ
        }
    };

    const createAdjacencyList = async (nodes, edges) => {
        const adjacencyList = {};
        nodes.forEach(node => adjacencyList[node?.id] = []);
        edges.forEach(edge => adjacencyList[edge?.source].push(edge?.target));
        return adjacencyList;
    };

    const isPathAvailable = (adjacencyList, source, target) => {
        const visited = new Set();
        const dfs = (currentNode) => {
            if (currentNode === target) return true;
            visited.add(currentNode);
            for (let neighbor of adjacencyList[currentNode]) {
                if (!visited.has(neighbor)) {
                    if (dfs(neighbor)) return true;
                }
            }
            return false;
        };
        return dfs(source);
    };

    const updateNodeStatus = async (nodes, edges, subjectNodeId, authorizeNodeId) => {

        const adjacencyList = await createAdjacencyList(nodes, edges);

        return nodes.map(node => {

            if (node.id === subjectNodeId || node.id === authorizeNodeId) {
                return { ...node };
            } else {
                const isSubjectConnected = isPathAvailable(adjacencyList, subjectNodeId, node.id);
                const isAuthorizeConnected = isPathAvailable(adjacencyList, node.id, authorizeNodeId);

                let warning = node.data.warning;
                let warning_text = node.data.warning_text;
                let error = node.data.error;
                let error_text = node.data.error_text;

                if (isSubjectConnected && isAuthorizeConnected) {
                    warning = false; // เชื่อมครบทั้ง subject และ authorize
                    warning_text = [];
                } else if (isSubjectConnected && !isAuthorizeConnected) {
                    warning = true;
                    warning_text = ["Subject is connected, but authorized data is missing."]; // เชื่อม subject แล้ว แต่ยังไม่เชื่อม authorize
                } else if (!isSubjectConnected && isAuthorizeConnected) {
                    warning = true;
                    warning_text = ["Authorized data is connected, but subject is missing."]; // เชื่อม authorize แล้ว แต่ยังไม่เชื่อม subject
                } else {
                    warning = true;
                    warning_text = ["Connection to subject and authorized data is missing."]; // ยังไม่ได้เชื่อมทั้ง subject และ authorize 
                }

                return {
                    ...node,
                    data: {
                        ...node.data,
                        warning: warning,
                        warning_text: warning_text,
                        error: error,
                        error_text: error_text
                    },
                };
            }

        });
    };

    const handleConditionValidate = (node) => {
        let error = false;
        let error_text = [];
        if (node?.data?.general_properties?.conditions.length === 0) {
            error = true;
            error_text.push("No conditions set up.");
            return {
                ...node,
                data: {
                    ...node.data,
                    error: error,
                    error_text: error_text
                },
            }
        } else {
            // เพิ่มส่วนตรวจสอบว่า condition ที่สร้างไว้มีอยู่ใน resource attribute หรือไม่
            error = false;
            error_text = [];
            return { // return temporary
                ...node,
                data: {
                    ...node.data,
                    error: error,
                    error_text: error_text
                },
            }
        }
    };

    const handleSelectionValidate = (node) => {
        let error = false;
        let error_text = [];
        // const attr_resource = nodes.find(node => node.type === 'root');
        const attr_selection = node.data.general_properties.attribute_selection;
        const hasSelected = checkAttributeSelections(attr_selection);
        if (!hasSelected) {
            error = true;
            error_text.push("No attribute selected.");
            return {
                ...node,
                data: {
                    ...node.data,
                    error: error,
                    error_text: error_text
                },
            }
        } else {

            // เพิ่มส่วนตรวจสอบว่า attribute ที่เลือกไว้มีอยู่ใน resource attribute หรือไม่

            error = false;
            error_text = [];
            return { // return temporary
                ...node,
                data: {
                    ...node.data,
                    error: error,
                    error_text: error_text
                },
            }
        }
    };

    const handleValidatePrivacyError = (data) => {
        const validatedPrivacyConfig = data.map((privacy) => {
            if (privacy?.attribute?.name !== "" && privacy?.visible_length !== "") {
                return {
                    ...privacy,
                    error: false,
                    error_text: []
                }
            } else {
                return {
                    ...privacy,
                    error: true,
                    error_text: ["Please complete all required fields."]
                }
            }
        });
        return validatedPrivacyConfig;
    };

    const handleCheckPrivacyNode = (nodeValidate) => {

        const isValidated = handleValidatePrivacyError(nodeValidate.data.general_properties.privacies);
        const hasError = isValidated.some(obj => obj.error === true);

        if (hasError) {
            const node_privacy = {
                ...nodeValidate,
                data: {
                    ...nodeValidate.data,
                    general_properties: {
                        ...nodeValidate.data.general_properties,
                        privacies: isValidated
                    },
                    error: true,
                    error_text: ["Privacy configuration for the data is missing."]
                }
            }
            return node_privacy;
        } else {
            const node_privacy = {
                ...nodeValidate,
                data: {
                    ...nodeValidate.data,
                    general_properties: {
                        ...nodeValidate.data.general_properties,
                        privacies: isValidated
                    },
                    error: false,
                    error_text: []
                }
            }
            return node_privacy;
        }

    };

    const handlePrivacyValidate = (node) => {
        if (node?.data?.general_properties?.privacies.length === 0) {
            const error = true;
            const error_text = ["Privacy configuration for the data is missing."];
            return {
                ...node,
                data: {
                    ...node.data,
                    error: error,
                    error_text: error_text
                },
            }
        } else {
            // เพิ่มส่วนตรวจสอบว่า privacy ที่สร้างไว้มีอยู่ใน resource attribute หรือไม่

            const nodeValidate = handleCheckPrivacyNode(node); // ตรวจสอบความถูกต้องของ privacy configuration

            return nodeValidate;
        }
    };

    const handleValidateData = async (updateNodes) => {

        const updatedErrorNodes = updateNodes.map((node) => {
            if (node.data.warning === false) {
                if (node.type === 'condition') {
                    const updatedNode = handleConditionValidate(node);
                    return updatedNode;
                } else if (node.type === 'selection') {
                    const updatedNode = handleSelectionValidate(node);
                    return updatedNode;
                } else if (node.type === 'privacy') {
                    const updatedNode = handlePrivacyValidate(node);
                    return updatedNode;
                } else {
                    return { ...node }
                }
            }
            return { ...node };
        });

        return updatedErrorNodes;
    };

    const shouldConnect = (sourceNode, targetNode) => {
        if (sourceNode.type === "subject" && targetNode.type !== "subject") {
            return true;
        } else if (sourceNode.type !== "subject" && targetNode.type !== "subject") {
            return true;
        } else {
            return false;
        }
    };

    const shouldShowHandle = (start_node, node) => {
        if (start_node.type === 'subject') {
            if (start_node.type !== node.type) {
                return true;
            } else {
                return false;
            }
        } else if (start_node.type !== 'subject') {
            if (node.type !== 'subject') {
                return true;
            } else {
                return false;
            }
        }
    };

    const onConnectStart = (event, params) => {
        if (!isReconnectRef.current) { // ตรวจสอบว่าไม่ใช่ reconnect
            const start_node = getNode(params.nodeId);
            setNodes(nodes => nodes.map((node) => ({
                ...node,
                data: { ...node.data, showHandle: shouldShowHandle(start_node, node) }
            })));
        }
    };

    const onConnect = useCallback(
        async (params) => {
            if (params.source === params.target) { // ไม่ให้เชื่อมเข้าตัวมันเอง
                return;
            }
            const isDuplicate = edges.some(
                (edge) => edge.source === params.source && edge.target === params.target
            );
            if (isDuplicate) { // เช็กเส้นซ้ำ
                return;
            }

            const sourceNode = getNode(params.source);
            const targetNode = getNode(params.target);

            if (sourceNode.type !== 'subject' && targetNode.type === 'subject') {
                return;
            } else if (sourceNode.type === 'subject' && targetNode.type === 'subject') {
                return;
            }

            const edgeColor = sourceNode.type === 'subject' ? 'red' : 'gray';

            const newEdge = {
                ...params,
                style: { stroke: edgeColor, strokeWidth: 2 },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: edgeColor
                }
            };

            const newEdges = addEdge(newEdge, edges);
            setEdges(newEdges);

            const subjectNodeId = nodes.find(node => (node.type === 'subject' && node.root === true));
            const authorizeNodeId = nodes.find(node => node.type === 'root');

            const updatedInheritNodes = await handleCheckInheritData(targetNode, sourceNode); // ตรวจสอบการสืบทอด

            const updatedStatusNodes = await updateNodeStatus(updatedInheritNodes, newEdges, subjectNodeId.id, authorizeNodeId.id); // ตรวจสอบการเชื่อมต่อข้อมูล

            const updatedNodes = await handleValidateData(updatedStatusNodes); // ตรวจสอบความถูกต้องข้อมูล

            setNodes(updatedNodes);

        },
        [setEdges, setNodes, nodes, edges, getNode],
    );

    const shouldShowHandleConnectEnd = (nodeSelected, node) => {
        if (nodeSelected?.id === node?.id && nodeSelected?.type !== 'root') {
            return true;
        } else {
            return false;
        }
    };

    const onConnectEnd = () => {
        setTimeout(() => {
            isReconnectRef.current = false; // รีเซ็ต reconnect หลัง event จบ
            setNodes(nodes => nodes.map(node => ({ ...node, data: { ...node.data, showHandle: shouldShowHandleConnectEnd(selectedNode, node) } })));
        }, 0);
    };

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    const shouldShowReConnectSubjectStart = (node) => {
        if (node.type !== 'root') {
            return true;
        } else {
            return false;
        }
    };

    const shouldShowReConnectStart = (node) => {
        if (node.type !== 'subject') {
            return true;
        } else {
            return false;
        }
    };

    const onReconnectStart = (event, edge, handleType) => {
        isReconnectRef.current = true; // ตั้งค่า reconnect
        if (handleType === 'target') {
            setNodes(nodes => nodes.map((node) => ({
                ...node,
                data: { ...node.data, showHandle: shouldShowReConnectSubjectStart(node) }
            })));
        } else {
            setNodes(nodes => nodes.map((node) => ({
                ...node,
                data: { ...node.data, showHandle: shouldShowReConnectStart(node) }
            })));
        }
    };

    const onReconnect = useCallback(async (oldEdge, newConnection) => {

        if (newConnection.source === newConnection.target) {
            return;
        }

        const sourceNode = getNode(newConnection.source);
        const targetNode = getNode(newConnection.target);
        const validateNodes = shouldConnect(sourceNode, targetNode);
        const newEdges = reconnectEdge(oldEdge, newConnection, edges);

        if (validateNodes) {
            const updateEdgeColor = newEdges.map((edge) => {
                const sourceEdge = getNode(edge.source);
                const edgeColor = sourceEdge.type === 'subject' ? 'red' : 'gray';

                const newEdge = {
                    ...edge,
                    selected: false,
                    style: { stroke: edgeColor, strokeWidth: 2 },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: edgeColor
                    }
                };
                return newEdge;
            });
            setEdges(updateEdgeColor);
        }

        const subjectNodeId = nodes.find(node => (node.type === 'subject' && node.root === true));
        const authorizeNodeId = nodes.find(node => node.type === 'root');

        const updatedInheritNodes = await handleCheckInheritData(targetNode, sourceNode); // ตรวจสอบการสืบทอด

        const updatedStatusNodes = await updateNodeStatus(updatedInheritNodes, newEdges, subjectNodeId.id, authorizeNodeId.id); // ตรวจสอบการเชื่อมต่อข้อมูล

        const updatedNodes = await handleValidateData(updatedStatusNodes); // ตรวจสอบความถูกต้องข้อมูล

        setNodes(updatedNodes);


    }, [nodes, edges, getNode, setEdges, setNodes]);

    const handleConfirm = async () => {
        setLoadingDialog(true);
        const now = Date.now();
        let update = {
            regulatory_diagram_id: regulatoryFile.regulatory_diagram_id,
            regulatory_diagram_name: regulatoryFile.regulatory_diagram_name,
            owner_id: regulatoryFile.creator_id,
            owner: regulatoryFile.creator_name,
            updater_id: profile.uprofile.inum,
            updater: profile.uprofile.displayName,
            created_at: regulatoryFile.create_timestamp,
            updater_at: now,
            description: regulatoryFile.description,
            validated: regulatoryFile.validated,
        }
        if (nodes.length > 0 || edges.length > 0) {
            update = {
                ...update,
                diagram: {
                    nodes: nodes,
                    edges: edges
                }
            };
        }

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/regulatory-diagram/update-regulatory/${regulatoryFile.regulatory_diagram_id}`, requestOptions);
        if (response.status === 200) {
            setLoadingDialog(false);
            setOpenSave(false);
            setResponseStatus("Regulatory diagram updated successfully");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setLoadingDialog(false);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }
    };

    const handleCancel = () => {
        setOpenSave(false);
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const toggleMinimap = () => {
        setShowMinimap(!showMinimap);
    };

    useEffect(() => {
        async function updateData() {
            const updatedNodes = await handleValidateData(nodes);
            setNodes(updatedNodes);
            setUpdateProperties(false);
        };
        if (updateProperties) {
            updateData();
        }
    }, [updateProperties, nodes]);

    const nodesWithUpdatedData = useMemo(() => {
        return nodes.map((node) => {
            if (node.type === 'subject') {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        onAddNode: () => onAddNode(node),
                    },
                };
            }
            return node;
        });
    }, [nodes, onAddNode]);

    const onDelete = async (deletedData) => {
        const deletedEdges = deletedData.edges;
        const deletedNodes = deletedData.nodes;
        let newEdges = [];
        let newNodes = [];
        const subjectNodeId = nodes.find(node => (node.type === 'subject' && node.root === true));
        const authorizeNodeId = nodes.find(node => node.type === 'root');

        if (deletedEdges.length > 0) {
            newEdges = edges.filter(edge =>
                !deletedEdges.some(deletedEdge => deletedEdge.id === edge.id)
            );
            setEdges(newEdges);
            const updatedStatusNodes = await updateNodeStatus(nodes, newEdges, subjectNodeId.id, authorizeNodeId.id); // ตรวจสอบการเชื่อมต่อข้อมูล
            const updatedNodes = await handleValidateData(updatedStatusNodes); // ตรวจสอบความถูกต้องข้อมูล
            setNodes(updatedNodes);
        }
        if (deletedNodes.length > 0) {
            newNodes = nodes.filter(node =>
                !deletedNodes.some(item => node.id === item.id)
            );
            setOpenProperties(false);
            dispatch(clearSelectedNodeRedux());
            const updatedStatusNodes = await updateNodeStatus(newNodes, newEdges, subjectNodeId.id, authorizeNodeId.id); // ตรวจสอบการเชื่อมต่อข้อมูล
            const updatedNodes = await handleValidateData(updatedStatusNodes); // ตรวจสอบความถูกต้องข้อมูล
            setNodes(updatedNodes);
        }
    };

    const handleResourceUpdateClose = () => {
        setOpenResourceUpdate(false);
    };

    const handleNotificationDPEClose = () => {
        setOpenNotificationDpe(false);
    };

    const onEdgeClick = (event, edge) => {
        setNodes(nodes => nodes.map((node) => ({
            ...node,
            data: { ...node.data, showHandle: false }
        })));
        setFirstCreate(false);
        setOpenProperties(false);
        dispatch(clearSelectedNodeRedux());
    };

    const handleSettingResource = useCallback(() => {
        setOpenNotificationDpe(false);
        const root_nodes = nodes.find((node) => node.type === 'root');
        setNodes((nds) =>
            nds.map((node, index) => ({ ...node, selected: node.type === 'root' }))
        );
        dispatch(setSelectedNodeRedux(root_nodes));
        setOpenProperties(true);
        dispatch(setOpenRightSide(true));
    }, [nodes]);

    return (
        <div className='h-[calc(100vh-64px)] relative'>
            {regulatoryFile &&
                <>
                    <ReactFlow
                        nodes={nodesWithUpdatedData}
                        edges={edgesWithUpdatedData}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnectStart={onConnectStart}
                        onReconnectStart={onReconnectStart}
                        onConnectEnd={onConnectEnd}
                        onConnect={onConnect}
                        onReconnect={onReconnect}
                        onNodeClick={onNodeClick}
                        onPaneClick={onPaneClick}
                        onEdgeClick={onEdgeClick}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onMoveEnd={saveViewportState}
                        onNodeDoubleClick={onNodeDoubleClick}
                        onEdgeMouseEnter={onEdgeMouseEnter}
                        onEdgeMouseLeave={onEdgeMouseLeave}
                        onDelete={onDelete}
                    // fitView
                    >
                        {showMinimap && <MiniMap style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'none',
                            width: 200,
                            border: '1px solid #ccc',
                        }} />}
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <Tooltip
                                    title="save"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <SaveIcon />
                                </Tooltip>
                            </ControlButton>
                            <ControlButton onClick={() => toggleMinimap()}>
                                <Tooltip
                                    title="minimap"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    {showMinimap ? (
                                        <LayersIcon />
                                    ) : (
                                        <LayersClearIcon />
                                    )}
                                </Tooltip>
                            </ControlButton>
                        </Controls>
                        <Background style={{ backgroundColor: "#fff" }} />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            // flexShrink: 0,
                            zIndex: 1,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <RegulatoryRightSide
                            selectedNode={selectedNode}
                            nodes={nodes}
                            setNodes={setNodes}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenSelectResource={setOpenSelectResource}
                            setOpenCreateCondition={setOpenCreateCondition}
                            setOpenAttributeSelection={setOpenAttributeSelection}
                            setOpenCreateSubjectConstraint={setOpenCreateSubjectConstraint}
                            setUpdateProperties={setUpdateProperties}
                        />
                    </Drawer>
                    <SelectResourceDialog
                        open={openSelectResource}
                        setOpen={setOpenSelectResource}
                        selectedNode={selectedNode}
                        setNodes={setNodes}
                    />
                    <CreateConditionDialog
                        open={openCreateCondition}
                        setOpen={setOpenCreateCondition}
                        selectedNode={selectedNode}
                        nodes={nodes}
                        setNodes={setNodes}
                    />
                    <AttributeSelection
                        open={openAttributeSelection}
                        openAttributeSelection={openAttributeSelection}
                        setOpen={setOpenAttributeSelection}
                        selectedNode={selectedNode}
                        nodes={nodes}
                        setNodes={setNodes}
                        setUpdateProperties={setUpdateProperties}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                            position: 'absolute'
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                    <ConfirmDialogReuse
                        open={openSave}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">Are you sure you want to save this diagram?</Typography>
                            </div>
                        }
                        actions={
                            <div className='grid grid-cols-2 gap-2'>
                                <LoadingButton
                                    variant="contained"
                                    disabled={loadingDialog}
                                    loading={loadingDialog}
                                    onClick={() => handleConfirm()}
                                >
                                    Confirm
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        }
                    />
                    <ResponseDialog
                        open={openResponse}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">{responseStatus}</Typography>
                            </div>
                        }
                        actions={
                            <Button
                                variant="outlined"
                                onClick={() => onResponseClose()}
                            >
                                Close
                            </Button>
                        }
                    />
                    <DialogReuse
                        open={openResourceUpdate}
                        onClose={handleResourceUpdateClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        title={"Resource Data Update"}
                        content={
                            <div className='px-6 py-2'>
                                {resourceUpdateDetail?.added.length > 0 &&
                                    <Box mb={1}>
                                        <Typography variant="body1" gutterBottom>Added data include:
                                            {resourceUpdateDetail?.added.map((item, index) => {
                                                return (
                                                    <Typography variant="body2" key={index}>- {item.name}</Typography>
                                                )
                                            })}
                                        </Typography>
                                    </Box>
                                }
                                {resourceUpdateDetail?.removed.length > 0 &&
                                    <Box>
                                        <Typography variant="body1" gutterBottom>Removed data include:
                                            {resourceUpdateDetail?.removed.map((item, index) => {
                                                return (
                                                    <Typography variant="body2" key={index}>- {item.name}</Typography>
                                                )
                                            })}
                                        </Typography>
                                    </Box>
                                }
                                {resourceUpdateDetail?.modified.length > 0 &&
                                    <Box>
                                        <Typography variant="body1">Data type change:
                                            {resourceUpdateDetail?.modified.map((item, index) => {
                                                return (
                                                    <Typography variant="body2" key={index}>- [{item.name}]: {item.oldDataType} {"→"} {item.newDataType} </Typography>
                                                )
                                            })}
                                        </Typography>
                                    </Box>
                                }
                            </div>
                        }
                        actions={
                            <LoadingButton variant="contained" onClick={() => { }}>Ok</LoadingButton>
                        }
                    />
                    <DialogReuse
                        open={openNotificationDpe}
                        onClose={handleNotificationDPEClose}
                        fullWidth={true}
                        maxWidth={"sm"}
                        title={"DPE Data Update"}
                        content={
                            <div className='px-6 py-2'>
                                <Box mb={1}>
                                    <Typography variant="body1" gutterBottom>{notificationDpeDetail}</Typography>
                                </Box>
                            </div>
                        }
                        actions={
                            <LoadingButton variant="contained" onClick={() => {
                                if (notificationDpeEvent === 'dpe_id') {
                                    handleSettingResource()
                                } else {
                                    handleNotificationDPEClose() // รอข้อมูล DPE ทดสอบ เพื่อนำไปเช็ก event ตามที่ note ไว้
                                }
                            }}>Next</LoadingButton>
                        }
                    />
                    <CreateSubjectConstraintDialog
                        open={openCreateSubjectConstraint}
                        setOpen={setOpenCreateSubjectConstraint}
                        setNodes={setNodes}
                        selectedNode={selectedNode}
                    />
                </>
            }
        </div>
    )
}

export default RegulatoryDiagram