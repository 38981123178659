import React from 'react'
import { Handle, Position } from '@xyflow/react';
import { Box, Typography, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { CustomTooltip } from '../../../../components/TooltipCustomStyle';
import TooltipStatusTitle from '../../../../components/TooltipStatusTitle';

const RootNode = ({ data, selected }) => {

    const theme = useTheme();

    return (
        <div style={{
            padding: '10px 15px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100px',
            borderRadius: '50%',
            backgroundColor: '#fff',
            // border: data?.isSelected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
            position: 'relative',
        }}
        >
            {data?.general_properties?.resource === undefined &&
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000,
                        textAlign: 'center',
                        // backgroundColor: '#fff'
                    }}
                >
                    <CustomTooltip title="No Resource data available. Please select the information." arrow placement="top" tooltipcolorstyle={theme.palette.warning.main}>
                        <ErrorIcon sx={{ color: theme.palette.warning.main, fontSize: 18, marginLeft: '2px', bgcolor: '#fff' }} />
                    </CustomTooltip>
                </div>
            }
            <div style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
            }}>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10
                    }}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            {(data?.error_dpe === true) &&
                <div
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '85%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000,
                        textAlign: 'center',
                    }}
                >
                    <CustomTooltip title={<TooltipStatusTitle headerTitle="Error" listDetails={data?.error_dpe_text} />} arrow placement="top" tooltipcolorstyle={theme.palette.error.main}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                background: '#fff',
                                width: 15,
                                height: 15,
                                marginTop: '5px'
                            }}
                        >
                            <ErrorIcon sx={{
                                color: theme.palette.error.main,
                                fontSize: 18,
                            }} />
                        </Box>
                    </CustomTooltip>
                </div>
            }
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                // isConnectable={data.showHandle ? true : false}
                isConnectableStart={false}
                isConnectableEnd={true}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                // isConnectable={data.showHandle ? true : false}
                isConnectableStart={false}
                isConnectableEnd={true}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                // isConnectable={data.showHandle ? true : false}
                isConnectableStart={false}
                isConnectableEnd={true}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: 'transparent',
                    border: 'none',
                }}
                // isConnectable={data.showHandle ? true : false}
                isConnectableStart={false}
                isConnectableEnd={true}
            />
        </div >
    );
};

export default RootNode;