import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import DialogReuse from '../../ReuseComponents/DialogReuse';
import ResponseDialog from '../../ReuseComponents/ConfirmDialogReuse';
import { setRegulatoryFile } from '../../../../redux/regulatoryFileSlice';
import { getRegulatoryFileAction } from '../../../../redux/regulatoryFileActionSlice';

function EditRegulatoryDialog(props) {

    const { open, setOpen, setStatusUpdate } = props;
    const dispatch = useDispatch();
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    const [regulatoryDiagramName, setRegulatoryDiagramName] = useState("");
    const [regulatoryDiagramNameError, setRegulatoryDiagramNameError] = useState(false);
    const [regulatoryDiagramNameTextError, setRegulatoryDiagramNameTextError] = useState("");
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [dpes, setDpes] = useState([]);
    const [dpeSelected, setDpeSelected] = useState("");
    const [resources, setResources] = useState([]);
    const [resourceSelected, setResourceSelected] = useState("");
    const [disableEdit, setDisableEdit] = useState(true);
    const [disableResource, setDisableResource] = useState(true);
    const regulatoryAction = useSelector(getRegulatoryFileAction);

    const [profile, setProfile] = useState();
    const [project, setProject] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClose = () => {
        setRegulatoryDiagramName("");
        setRegulatoryDiagramNameError(false);
        setRegulatoryDiagramNameTextError("");
        setDescription("");
        setDisabled(true);
        setResources([]);
        setDpeSelected("");
        setResourceSelected("");
        setOpen(false);
    };

    const handleGetRegulatoryDiagram = async (regulatory_diagram_id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatory/${regulatory_diagram_id}`);
        if (response.ok) {
            const result = await response.json();
            dispatch(setRegulatoryFile(result));
            localStorage.setItem('regulatory-file', JSON.stringify(result));
        } else {
            console.error(response.status);
        }
    };

    const getResourceAttributeList = async (initialNodes) => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected.resource_schema_endpoint}?resource=${resourceSelected}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    checked: false,
                };
            });

            const initial_attributes = initialNodes.map((node) => {
                if (node.type === 'root') {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                dpe: dpeSelected,
                                resource: resourceSelected,
                                attribute_selection: new_result
                            }
                        }
                    };
                }
                return node;
            });
            return initial_attributes;
        } else {
            console.error(response);
            return false;
        }
    };

    const handleEdit = async () => {

        setLoading(true);
        const validate = await handleValidate();
        if (validate) {

            const rootNode = regulatoryAction?.diagram?.nodes.find((node) => node.type === 'root');
            if (rootNode?.data?.general_properties?.resource !== resourceSelected) { // ถ้า resource มีการเปลี่ยนให้ Get Attributes มาใหม่
                // Get Attribute from Resource
                const nodeUpdate = await getResourceAttributeList(regulatoryAction?.diagram?.nodes);
                if (nodeUpdate) {
                    const trim_regulatory_name = regulatoryDiagramName.trim();
                    const update_ts = Date.now();

                    const requestOptions = {
                        method: "PUT",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            ...regulatoryAction,
                            regulatory_diagram_name: trim_regulatory_name,
                            updater_id: profile.uprofile.inum,
                            updater: profile.uprofile.displayName,
                            updater_at: update_ts,
                            description: description,
                            // validated: true,
                            diagram: {
                                nodes: nodeUpdate, // ใช้ Attributes ใหม่ที่ updated
                                edges: regulatoryAction?.diagram?.edges
                            }
                        })
                    };

                    const response = await fetch(process.env.REACT_APP_API_URL + '/regulatory-diagram/update-regulatory/' + regulatoryAction.regulatory_diagram_id, requestOptions);
                    if (response.status === 200) {
                        setLoading(false);
                        setStatusUpdate(true);
                        handleClose();
                        handleGetRegulatoryDiagram(regulatoryAction.regulatory_diagram_id);
                    } else {
                        console.error(response.status);
                        setLoading(false);
                        setResponseStatus(`Error status: ${response.status}`);
                        setOpenResponse(true);
                    }
                }
            } else {
                const trim_regulatory_name = regulatoryDiagramName.trim();
                const update_ts = Date.now();

                const requestOptions = {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...regulatoryAction,
                        regulatory_diagram_name: trim_regulatory_name,
                        updater_id: profile.uprofile.inum,
                        updater: profile.uprofile.displayName,
                        updater_at: update_ts,
                        description: description,
                        // validated: true,
                        diagram: {
                            nodes: regulatoryAction?.diagram?.nodes, // ใช้ Attributes node เดิม
                            edges: regulatoryAction?.diagram?.edges
                        }
                    })
                };

                const response = await fetch(process.env.REACT_APP_API_URL + '/regulatory-diagram/update-regulatory/' + regulatoryAction.regulatory_diagram_id, requestOptions);
                if (response.status === 200) {
                    setLoading(false);
                    setStatusUpdate(true);
                    handleClose();
                    handleGetRegulatoryDiagram(regulatoryAction.regulatory_diagram_id);
                } else {
                    console.error(response.status);
                    setLoading(false);
                    setResponseStatus(`Error status: ${response.status}`);
                    setOpenResponse(true);
                }
            }
        }

    };

    const handleDuplicate = async () => {
        const trim_regulatory_name = regulatoryDiagramName.trim();
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project_id: project.project.project_id,
                regulatory_diagram_name: trim_regulatory_name
            })
        };
        const response = await fetch(process.env.REACT_APP_API_URL + '/regulatory-diagram/check-duplicate-regulatory', requestOptions);
        if (response.status === 200) {
            const result = await response.json();
            setDisabled(result.isDuplicate)
            if (result.isDuplicate) {
                setRegulatoryDiagramNameError(true)
                setRegulatoryDiagramNameTextError("The name already exists in the system.")
                return true;
            } else {
                setRegulatoryDiagramNameError(false)
                setRegulatoryDiagramNameTextError("")
                return false;
            }
        } else {
            console.error(response.status);
            setRegulatoryDiagramNameError(true)
            setRegulatoryDiagramNameTextError("Failed to retrieve data. Please try again later.")
            setDisabled(true)
            return true;
        }
    };

    const handleValidate = async () => {
        // ตรวจสอบว่าไม่มีการใส่ชื่อ
        if (!regulatoryDiagramName) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name field cannot be empty.")
            return false;
        }

        // ตรวจสอบว่าไม่มีช่องว่าง
        if (/\s/.test(regulatoryDiagramName)) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name should not contain spaces.")
            return false;
        }

        // ตรวจสอบว่ามีเฉพาะตัวอักษรภาษาอังกฤษ ตัวเลข และเครื่องหมาย `_` หรือ `-`
        const regex = /^[a-zA-Z0-9_-]+$/;
        if (!regex.test(regulatoryDiagramName)) {
            setRegulatoryDiagramNameError(true)
            setDisabled(true)
            setRegulatoryDiagramNameTextError("The name should contain only English letters, numbers, underscores (_), or dashes (-).")
            return false;
        }

        if (regulatoryAction.regulatory_diagram_name !== regulatoryDiagramName) { // ตรวจสอบว่ามีการแก้ไขชื่อ
            const isDuplicate = await handleDuplicate();
            if (isDuplicate) { // ตรวจสอบว่าไม่มีชื่อซ้ำในระบบ
                return false;
            }
        }
        // else {
        //     setRegulatoryDiagramNameError(false);
        //     setRegulatoryDiagramNameTextError("");
        //     if (regulatoryAction.description === description) { // ตรวจสอบว่าไม่มีการแก้ไขคำอธิบาย
        //         setDisabled(true);
        //         return false;
        //     }
        // }

        setRegulatoryDiagramNameError(false);
        setRegulatoryDiagramNameTextError("");
        setDisabled(false);
        return true;
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const getDpeList = async (regulatoryDPE) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/get-dpes/${project.project.project_id}`);
        if (response.ok) {
            let DPEs = []
            const dicovery = await response.json();
            for (const dLink of dicovery) {
                const dpe_resp = await fetch(dLink.dpe_discovery_url);
                if (dpe_resp.ok) {
                    const dpe = await dpe_resp.json();
                    DPEs.push({
                        ...dLink,
                        ...dpe
                    })
                }
                else {
                    DPEs.push({
                        ...dLink,
                        resources_name: dLink.dpe_name,
                        resource_type: "DB_Null",
                        resource_connection: {
                            status: "Error",
                            message: "connection refused!"
                        }
                    })
                }
            }
            const hasDPE = DPEs.find((dpe) => dpe.resources_name === regulatoryDPE.resources_name);
            if (hasDPE) {
                setDpeSelected(hasDPE);
            } else {
                setDpeSelected("-");
            }
            setDpes(DPEs);
        }
    };

    const getResourceList = async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected.resources_endpoint}`, option);
        if (response.ok) {
            const result = await response.json();
            setResources(result);
        } else {
            console.error(response);
        }
    };

    const handleChangeDPE = useCallback((newChange) => {
        if (dpeSelected?.resources_name !== newChange.resources_name) {
            setResourceSelected("");
        }
        setDpeSelected(newChange);
    }, [dpeSelected]);

    const handleChangeResource = useCallback(async (newChange) => {
        if (resourceSelected !== newChange) {
            const validate = await handleValidate();
            if (validate) {
                setDisableEdit(false);
            } else {
                setDisableEdit(true);
            }
            setResourceSelected(newChange);
        }
    }, [resourceSelected]);

    useEffect(() => {
        if (dpeSelected) {
            setDisableResource(false);
            getResourceList();
        } else {
            setDisableResource(true);
        }
    }, [dpeSelected]);

    useEffect(() => {
        if (dpeSelected && resourceSelected && !disabled && open) {
            setDisableEdit(false);
        } else {
            setDisableEdit(true);
        }
    }, [dpeSelected, resourceSelected, disabled, open])

    useEffect(() => {
        if (open && regulatoryAction && project) {
            setRegulatoryDiagramName(regulatoryAction?.regulatory_diagram_name);
            setRegulatoryDiagramNameError(false);
            setRegulatoryDiagramNameTextError("");
            setDescription(regulatoryAction?.description);
            setDisabled(true);
            setResources([]);
            const rootNode = regulatoryAction?.diagram?.nodes.find((node) => node.type === 'root');
            if (rootNode) {
                getDpeList(rootNode?.data?.general_properties?.dpe);
                setResourceSelected(rootNode?.data?.general_properties?.resource);
            }
        }
    }, [open, regulatoryAction, project])

    return (
        <Fragment>
            <DialogReuse
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                title={"Edit Regulatory"}
                content={
                    <Box
                        component="form"
                        autoComplete="off"
                    >
                        <TextField
                            required
                            id="regulatory-name"
                            label="Regulatory Name"
                            fullWidth
                            margin="normal"
                            value={regulatoryDiagramName}
                            onChange={(e) => setRegulatoryDiagramName(e.target.value)}
                            onBlur={handleValidate}
                            error={regulatoryDiagramNameError}
                            helperText={regulatoryDiagramNameTextError}
                        />
                        <TextField
                            id="description"
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="data-proxy-engine-label">Data Proxy Engine</InputLabel>
                            <Select
                                labelId="data-proxy-engine-label"
                                fullWidth
                                label="Data Proxy Engine"
                                value={dpeSelected}
                                onChange={(e) => {
                                    handleChangeDPE(e.target.value);
                                }}
                                renderValue={(selected) => selected.resources_name}
                            >
                                {dpes.map((option) => (
                                    <MenuItem key={option.dpe_id} value={option}>
                                        {option.resources_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="resource-label">Resource</InputLabel>
                            <Select
                                labelId="resource-label"
                                fullWidth
                                label="Resource"
                                disabled={disableResource}
                                value={resourceSelected}
                                onChange={(e) => {
                                    handleChangeResource(e.target.value);
                                }}
                            >
                                {resources.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                }
                actions={
                    <LoadingButton variant="contained" disabled={disableEdit} loading={loading} onClick={() => handleEdit()}>Edit</LoadingButton>
                }
            />
            <ResponseDialog
                open={openResponse}
                maxWidth={"sm"}
                content={
                    <div>
                        <Typography variant="subtitle1">{responseStatus}</Typography>
                    </div>
                }
                actions={
                    <Button
                        variant="outlined"
                        onClick={() => onResponseClose()}
                    >
                        Close
                    </Button>
                }
            />
        </Fragment>
    )
}

export default EditRegulatoryDialog