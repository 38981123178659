import { Box, Button, Grid, IconButton, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CardAdd from '../../components/CardAdd';
import PolylineIcon from '@mui/icons-material/Polyline';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch } from 'react-redux';
import { clearSubjectFile } from '../../redux/subjectFileSlice';
import { clearObjectFile } from '../../redux/objectFileSlice';
import { setOpenRightSide } from '../../redux/openRightSideSlice';
import { clearRegulatoryFile } from '../../redux/regulatoryFileSlice';

function ProjectWorkSpace() {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [subjectCount, setSubjectCount] = useState(0)
    const [objectCount, setObjectCount] = useState(0)
    const [regulatoryCount, setRegulatoryCount] = useState(0)
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const getDRGCount = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/misc/drg-count/${project.project.project_id}`);
        if (response.status === 200) {
            const result = await response.json();
            setSubjectCount(result.subject_diagram_count)
            setObjectCount(result.object_diagram_count)
            setRegulatoryCount(result.regulatory_diagram_count)
        } else {
            console.error(response.status);
        }
    };

    const handleClearSelectedFile = () => {
        dispatch(clearRegulatoryFile());
        dispatch(setOpenRightSide(false));
    };

    useEffect(() => {
        if (project !== undefined) {
            getDRGCount();
        }
    }, [project])

    return (
        <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 overflow-auto w-full">
            <div className='pt-10'>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <IconButton sx={{ marginRight: 0.5, marginLeft: -1 }} aria-label="home" color="primary" component={Link} to="/editor">
                        <HomeIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                    <Typography component='span' variant='h4' fontWeight='bold'>{project?.project?.project_name}</Typography>
                </Box>
                <Box sx={{ marginTop: 5 }}>
                    <Typography component='span' variant='h5'>Policy</Typography>
                </Box>
                <Box sx={{ marginTop: 3 }}>
                    <CardAdd />
                </Box>
                <Box sx={{ marginTop: 10 }}>
                    <Typography component='span' variant='h5'>Data Regulatory Graph</Typography>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    padding: 2,
                    width: '100%',
                    borderRadius: 1,
                    backgroundColor: '#FFFFFF',
                    flexGrow: 1,
                    // boxShadow: 2
                    border: '1px solid #ccc'
                }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1 }}>
                                <Typography component='span' variant='h7' className='font-normal text-lg'>Manage the Subject, Object and Regulatory</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1 }}>
                                <Button
                                    size='medium'
                                    sx={{ textTransform: 'none' }}
                                    variant="contained"
                                    component={Link}
                                    to="/editor/regulatory"
                                    onClick={() => handleClearSelectedFile()}
                                >
                                    DRG Management
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ color: 'white', p: 1, paddingTop: 5 }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}>
                                    <PolylineIcon color="primary" sx={{ marginRight: 1 }} />
                                    <Typography component='span' variant='subtitle1' color={theme.palette.gray.main}>{regulatoryCount} Regulatory(s)</Typography>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </div>
        </section>
    )
}

export default ProjectWorkSpace