import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, CircularProgress, Divider, Slide, Stack, TextField, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { v4 as uuidv4 } from 'uuid';

function AddDPEFirst(props) {

    const { open, setOpen, setIsDPEUpdate } = props;

    const dpeInit = { url: "", conf: {} }
    const [validDPE, setValidDPE] = useState(false);
    const [host, setHost] = useState("");
    const [dpeDiscovery, setDPEDiscovery] = useState(dpeInit);
    const [error, setError] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        setDPEDiscovery(dpeInit)
        setValidDPE(false)
        setError(false)
    };

    const isDPEExisted = async (dpe_name, project_id) => {
        try {
            let options = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    "project_id": project_id,
                    "dpe_name": dpe_name
                })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/check-duplicate-dpe`, options);
            if (response.ok) {
                const dpe = await response.json()
                return dpe.isDuplicate
            }
            else throw response
        }
        catch (error) {
            setIsDuplicate(false)
            setDPEDiscovery(dpeInit)
            setValidDPE(false)
            setErrorMsg(error.message)
            setError(true)
        }
    }

    const handleHostEnterValue = (e) => {
        setHost(e.target.value)
    }

    const handleValidateDPEHost = async (e) => {
        if (host !== "") {
            try {
                setIsLoading(true)
                setError(false)
                const dpe_url = `https://${host}/.well-known/dpe-configuration`
                const res = await fetch(dpe_url)
                if (res.ok) {
                    const dpe = await res.json()
                    const isDuplicated = await isDPEExisted(dpe.resources_name, project.project.project_id)
                    setIsDuplicate(isDuplicated)
                    if (isDuplicated) {
                        setError(true)
                        setErrorMsg(`DPE '${dpe.resources_name}' is existed`)
                    }
                    else setError(false)

                    setDPEDiscovery({ url: dpe_url, conf: dpe })
                    setValidDPE(true)
                    setIsLoading(false)
                }
                else {
                    throw res
                }
            }
            catch (error) {
                setIsDuplicate(false)
                setDPEDiscovery(dpeInit)
                setValidDPE(false)
                setErrorMsg(error.message)
                setError(true)
                setIsLoading(false)
            }
        }
        else {
            setError(true)
            setErrorMsg("Please fill DPE host.")
        }
    }

    const handleAddDPE = async (e) => {
        setValidDPE(false)
        try {
            let options = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    "dpe_discovery_url": dpeDiscovery.url,
                    "dpe_id": uuidv4(),
                    "dpe_name": dpeDiscovery.conf.resources_name
                })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/add-dpe/${project.project.project_id}`, options);
            if (response.ok) {
                handleClose()
                setIsDPEUpdate(true);
            }
            else throw response
        }
        catch (error) {
            setErrorMsg(error.message)
            setError(true)
        }
    }

    return (
        <>
            {open && (
                <Slide in={open} mountOnEnter unmountOnExit>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '52px',
                            left: '0px',
                            width: '100%',
                            height: 'calc(100% - 52px)',
                            backgroundColor: '#fff',
                            zIndex: 2, // กำหนดให้สูงกว่าเนื้อหาของ Tab แต่ต่ำกว่า AppBar
                            padding: 3,
                        }}
                    >
                        <Box className="mx-auto pt-6 sm:px-6 md:px-20 lg:px-72 overflow-auto w-full">
                            <Typography variant='h6' sx={{ fontWeight: 'bold' }} pb={2}>Add Data Proxy Engine</Typography>
                            <Typography variant='inherit' className='py-4 text-[#939393]'>Please, enter DPE host.</Typography>
                            <Box className='px-8 py-6 flex flex-row items-center gap-3 rounded border-gray-400 border w-full'>
                                <Typography variant='subtitle1'>https://</Typography>
                                <TextField id="standard-basic" size='small' placeholder="Enter DPE host" variant="standard" onChange={handleHostEnterValue} />
                                <Typography variant='subtitle1'>/.well-known/dpe-configuration</Typography>
                                <Button variant='contained' size='small' onClick={handleValidateDPEHost}>connect</Button>
                            </Box>
                            {isLoading ? (
                                <div className='flex justify-center my-4'>
                                    <CircularProgress size={20} />
                                </div>
                            ) : (
                                <Divider sx={{ my: 4 }}>
                                    <Chip label="DPE Info." size="small" />
                                </Divider>
                            )

                            }

                            <Stack spacing={2} sx={{ mb: 2 }}>
                                <div className="grid grid-cols-6 gap-4">
                                    <Typography variant='inherit' className="col-start-1 col-span-2">Name:</Typography>
                                    <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resources_name ? dpeDiscovery.conf?.resources_name : "-"}</Typography>
                                </div>
                                <div className="grid grid-cols-6 gap-4">
                                    <Typography variant='inherit' className="col-start-1 col-span-2">URL:</Typography>
                                    <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.issuer ? dpeDiscovery.conf?.issuer : "-"}</Typography>
                                </div>
                                <div className="grid grid-cols-6 gap-4">
                                    <Typography variant='inherit' className="col-start-1 col-span-2">Resource Type:</Typography>
                                    <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resource_type ? dpeDiscovery.conf?.resource_type : "-"}</Typography>
                                </div>
                                <div className="grid grid-cols-6 gap-4">
                                    <Typography variant='inherit' className="col-start-1 col-span-2">Resource Status:</Typography>
                                    <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resource_connection ? dpeDiscovery.conf?.resource_connection?.status : "-"}</Typography>
                                </div>
                            </Stack>

                            <div className='flex justify-center w-full items-center py-1'>
                                <div>{error && <div className='flex justify-center gap-2 text-red-800'><WarningIcon /><Typography variant='body2'>{errMsg}</Typography></div>}</div>
                            </div>
                            <div className='py-1 flex justify-center w-full items-center'>
                                <Button disabled={!validDPE || error || isDuplicate} variant='contained' size='medium' color='primary' onClick={handleAddDPE}>
                                    Add DPE
                                </Button>
                            </div>
                        </Box>
                    </Box>
                </Slide>
            )}
        </>
    )
}

export default AddDPEFirst