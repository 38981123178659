import { createSlice } from '@reduxjs/toolkit'

export const selectedNodeSlice = createSlice({
    name: 'selectedNode',
    initialState: {
        data: undefined,
    },
    reducers: {
        setSelectedNodeRedux: (state, action) => {
            state.data = action.payload;
        },
        clearSelectedNodeRedux: (state) => {
            state.data = undefined;
        },
    },
})

export const { setSelectedNodeRedux, clearSelectedNodeRedux } = selectedNodeSlice.actions;

export const getSelectedNodeRedux = (state) => state.selectedNode.data;

export default selectedNodeSlice.reducer