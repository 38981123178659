import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: props.tooltipcolorstyle,
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        "& .MuiTooltip-arrow": {
            color: props.tooltipcolorstyle,
        },
    },
}));