import React, { Fragment, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Button, Stack, Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function AttributeSelection(props) {

    const theme = useTheme();
    const { open, setOpen, selectedNode, setNodes, nodes, setUpdateProperties } = props;
    const [attributeSelected, setAttributeSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(true);
    const [resourceLabel, setResourceLabel] = useState("");

    const handleCheckboxChange = (event, name) => {
        const update = attributeSelected.map((attr) => {
            if (attr.name === name) {
                return {
                    ...attr,
                    checked: event.target.checked
                }
            }
            return attr;
        })
        setAttributeSelected(update)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {

        const update_nodes = nodes.map((node) => {
            if (node.id === selectedNode.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            attribute_selection: attributeSelected
                        }
                    }
                };
            }
            return node;
        });

        setNodes(update_nodes);
        setUpdateProperties(true);
        handleClose();
    };

    const handleSelectAll = () => {
        setAttributeSelected((prevSelected) =>
            prevSelected.map((attr) => {
                return { ...attr, checked: !selectAll };
            })
        );
        setSelectAll(!selectAll);
    };

    const handleGetResource = () => {
        const root_node = nodes.find((node) => node.type === "root");
        const is_resource = root_node?.data?.general_properties?.resource;
        if (is_resource) {
            setResourceLabel(is_resource);
        } else {
            setResourceLabel("-");
        }
    };

    useEffect(() => {
        if (open) {
            setAttributeSelected(selectedNode?.data?.general_properties?.attribute_selection);
            handleGetResource();
        }
    }, [open])

    useEffect(() => {
        if (attributeSelected) {
            const attribute_selected = attributeSelected.filter((attr) => (attr.checked === true));
            if (attribute_selected.length === attributeSelected.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        }
    }, [attributeSelected])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div style={{ width: '100%' }}>
                        <Typography variant='h6' color={theme.palette.white.main}>Attribute Selection</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <Stack
                            sx={{
                                width: '100%',
                                border: '1px solid #ccc',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '10px'
                            }}
                            direction='row'
                        >
                            <Box display="flex">
                                <Typography
                                    variant="caption"
                                    display="block"
                                    color="primary"
                                    noWrap
                                    sx={{
                                        marginRight: '5px',
                                    }}
                                >
                                    Resource:
                                </Typography>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    color="black"
                                    noWrap
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        // maxWidth: '40%'
                                        maxWidth: '200px'
                                    }}
                                >
                                    {resourceLabel}
                                </Typography>
                            </Box>
                        </Stack>
                        <div
                            style={{
                                border: '1px solid #ccc',
                                borderBottomLeftRadius: '5px',
                                borderBottomRightRadius: '5px',
                                maxHeight: 'calc(100vh - 390px)',
                                overflow: 'auto'
                            }}
                        >
                            <List sx={{ padding: '0px' }}>
                                <ListItem
                                    sx={{
                                        padding: '5px 0px 5px 10px',
                                    }}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={selectAll}
                                            onChange={(event) => setSelectAll(event.target.checked)}
                                            onClick={handleSelectAll}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </ListItem>
                                {attributeSelected.map((item, index) => {
                                    return (
                                        <ListItem
                                            sx={{
                                                padding: '5px 0px 5px 10px',
                                            }}
                                            key={index}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={item.checked}
                                                    disableRipple
                                                    onChange={(event) => handleCheckboxChange(event, item.name)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" onClick={() => handleSave()}>Save</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default AttributeSelection