import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getSelectedNodeRedux } from '../../../../redux/selectedNodeSlice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function SelectResourceDialog(props) {

    const theme = useTheme();
    const { open, setOpen, setNodes } = props;
    const [disabled, setDisabled] = useState(true);
    const [dpes, setDpes] = useState([]);
    const [dpeSelected, setDpeSelected] = useState();
    const [resources, setResources] = useState([]);
    const [resourceSelected, setResourceSelected] = useState();
    const [project, setProject] = useState();
    const selectedNode = useSelector(getSelectedNodeRedux);
    const [dpeError, setDpeError] = useState(false);
    const [dpeErrorText, setDpeErrorText] = useState("");

    useEffect(() => {
        const project = JSON.parse(localStorage.getItem('project'));
        if (project) {
            setProject(project);
        }
    }, []);

    const handleClose = () => {
        setDpes([]);
        setResources([]);
        setDpeSelected();
        setResourceSelected();
        setOpen(false);
    };

    const getResourceAttribute = useCallback(async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected?.resource_schema_endpoint}?resource=${resourceSelected}`, option);
        if (response.ok) {
            const result = await response.json();
            const new_result = result.map((attr) => {
                return {
                    name: attr.column_name,
                    data_type: attr.data_type,
                    checked: false,
                };
            });
            setNodes((nds) => nds.map((node) => {
                if (node.id === selectedNode.id) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            error_dpe: false,
                            error_dpe_text: [],
                            general_properties: {
                                ...node.data.general_properties,
                                dpe: dpeSelected,
                                resource: resourceSelected,
                                attribute_selection: new_result
                            }
                        }
                    };
                }
                return node;
            }));
            handleClose();
        } else {
            console.error(response);
        }
    }, [dpeSelected, resourceSelected, selectedNode]);

    const handleSelect = () => {
        getResourceAttribute();
    };

    const fetchDpe = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/get-dpes/${project.project.project_id}`);
        if (response.ok) {
            let DPEs = []
            const dicovery = await response.json();
            for (const dLink of dicovery) {
                const dpe_resp = await fetch(dLink.dpe_discovery_url);
                if (dpe_resp.ok) {
                    const dpe = await dpe_resp.json();
                    DPEs.push({
                        ...dLink,
                        ...dpe
                    })
                }
                else {
                    DPEs.push({
                        ...dLink,
                        resources_name: dLink.dpe_name,
                        resource_type: "DB_Null",
                        resource_connection: {
                            status: "Error",
                            message: "connection refused!"
                        }
                    })
                }
            }
            setDpes(DPEs);
        }
    }, [project]);

    const fetchResources = useCallback(async () => {
        const iaam = window.iaam;
        const accessToken = await iaam.getAccessToken();
        let option = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `bearer ${accessToken}`
            }
        }
        const response = await fetch(`${dpeSelected?.resources_endpoint}`, option);
        if (response.ok) {
            const result = await response.json();
            setResources(result);
        } else {
            console.error(response);
        }
    }, [dpeSelected]);

    const handleChangeDPE = (newChange) => {
        if (dpeSelected?.dpe_id !== newChange.dpe_id) {
            setResourceSelected("");
            setDpeError(false);
            setDpeErrorText("");
        }
        setDpeSelected(newChange);
    };

    useEffect(() => {
        if (dpeSelected) {
            fetchDpe();
            fetchResources();
        }
    }, [dpeSelected]);

    useEffect(() => {
        if (open && selectedNode) {
            if (selectedNode?.type === 'root') {
                setDpeSelected(selectedNode.data.general_properties.dpe);
                setResourceSelected(selectedNode.data.general_properties.resource);
                if (selectedNode?.data?.error_dpe) {
                    setDpeError(true);
                    setDpeErrorText(selectedNode?.data?.error_dpe_text[0]);
                } else {
                    setDpeError(false);
                    setDpeErrorText("");
                }
            }
        }
    }, [open, selectedNode]);

    useEffect(() => {
        if (
            (dpeSelected?.dpe_id !== selectedNode?.data?.general_properties?.dpe?.dpe_id || resourceSelected !== selectedNode?.data?.general_properties?.resource)
            && (dpeSelected && resourceSelected)
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [dpeSelected, resourceSelected, selectedNode]);

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                fullWidth
                maxWidth="xs"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.primary.main, borderTopLeftRadius: 2, borderTopRightRadius: 2 }} id="customized-dialog-title">
                    <div>
                        <Typography variant='h6' color={theme.palette.white.main}>Select Resource</Typography>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ paddingX: 3, paddingY: 2 }}>
                        <TextField
                            id="data-proxy-engine"
                            sx={{ color: '#000' }}
                            fullWidth
                            select
                            margin="normal"
                            label="Data Proxy Engine"
                            value={dpeSelected?.resources_name || ""}
                            error={dpeError}
                            helperText={dpeErrorText}
                            onChange={(e) => {
                                handleChangeDPE(e.target.value);
                            }}
                        >
                            {dpeSelected?.resources_name !== selectedNode?.data?.general_properties?.dpe?.resources_name ? null :
                                <MenuItem value={dpeSelected?.resources_name} disabled>
                                    {dpeSelected?.resources_name}
                                </MenuItem>
                            }

                            {dpes.map((option) => (
                                <MenuItem key={option.dpe_id} value={option}>
                                    {option.resources_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            id="resource"
                            sx={{ color: '#000' }}
                            fullWidth
                            select
                            margin="normal"
                            label="Resource"
                            value={resourceSelected || ""}
                            onChange={(e) => {
                                setResourceSelected(e.target.value);
                            }}
                        >
                            {resourceSelected !== selectedNode?.data?.general_properties?.resource ? null :
                                <MenuItem value={resourceSelected} disabled>
                                    {resourceSelected}
                                </MenuItem>
                            }

                            {resources.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ m: 0, paddingX: 1, paddingY: 1 }}>
                        <Button variant="contained" disabled={disabled} onClick={() => handleSelect()}>Select</Button>
                    </Box>
                </DialogActions>
            </BootstrapDialog>
        </Fragment>
    )
}

export default SelectResourceDialog