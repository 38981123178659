import React from 'react'

function TooltipStatusTitle(props) {
    const { headerTitle, listDetails } = props;
    return (
        <>
            <div className='text-sm mb-1'>{headerTitle}</div>
            <div>{listDetails.map((text, index) => (
                <div key={index} className='text-xs'>- {text}</div>
            ))}</div>
        </>
    )
}

export default TooltipStatusTitle