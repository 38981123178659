import React from 'react'
import MainLayout from './MainLayout';
import DRGLayout from './Editor/DRG/DRGLayout';
import { Outlet, useLocation } from 'react-router-dom';

function Main() {

    const location = useLocation();
    const currentRoute = '/' + location.pathname.split('/')[1];

    return (
        <div
            sx={{ bgcolor: '#f3f3f3' }}
        >
            {(
                currentRoute === '/'
                || currentRoute === '/editor'
                || currentRoute === '/simulator'
                || currentRoute === '/deployer'
            ) && <MainLayout />}
        </div>
    )
}

export default Main