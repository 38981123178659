import { Box, Drawer, styled, Tab, Tabs } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactFlowProvider } from '@xyflow/react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import ObjectComponents from './components/ObjectComponents';
import AddDPEFirst from './components/AddDPEFirst';
import { getOpenDrawerLeft } from '../../../redux/drgMgtSlice';
import DPEManagement from '../../../components/DPEManagement';
import { setRegulatoryFile } from '../../../redux/regulatoryFileSlice';
import { getDpeRedux } from '../../../redux/dpesSlice';
import RegulatoryFiles from './components/RegulatoryFiles';
import RegulatoryDiagram from './components/RegulatoryDiagram';
import CreateRegulatoryDialog from './components/CreateRegulatoryDialog';
import { getRegulatoryFile } from '../../../redux/regulatoryFileSlice';
import EditRegulatoryDialog from './components/EditRegulatoryDialog';

const drawerWidth = 270;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0,
        },
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ padding: '0px', height: "100%" }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabObjectFiles = memo(({ nodes, edges, setOpenCreate, statusUpdate, setStatusUpdate, openEdit, setOpenEdit }) => {
    return <RegulatoryFiles
        nodes={nodes}
        edges={edges}
        setOpenCreate={setOpenCreate}
        statusUpdate={statusUpdate}
        setStatusUpdate={setStatusUpdate}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
    />;
});

const TabComponents = memo(() => {
    return <ObjectComponents />;
});

function RegulatoryPage() {

    const openDrawerLeft = useSelector(getOpenDrawerLeft);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [openCreate, setOpenCreate] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(true);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const [selectFile, setSelectFile] = useState();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const dpes = useSelector(getDpeRedux);
    const [openAddDPE, setOpenAddDPE] = useState(false);
    const [isDPEUpdate, setIsDPEUpdate] = useState(false);
    const [firstCreate, setFirstCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [regulatoryId, setRegulatoryId] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const root_node = nodes.find((node) => {
            if (node.type === "root" && node.data.general_properties.resource !== undefined) {
                return true;
            } else {
                return false;
            }
        })
        if (regulatoryFile && root_node) {
            setComponentDisabled(false);
        } else {
            setComponentDisabled(true);
        }
    }, [regulatoryFile, nodes])

    useEffect(() => {
        const selectFile = JSON.parse(localStorage.getItem('regulatory-file'));
        if (selectFile) {
            setSelectFile(selectFile);
        }
    }, []);

    useEffect(() => {
        if (selectFile?.regulatory_diagram_id) {
            const getLocalStorageFile = async (regulatory_diagram_id) => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatory/${regulatory_diagram_id}`);
                if (response.ok) {
                    const result = await response.json();
                    dispatch(setRegulatoryFile(result));
                    localStorage.setItem('regulatory-file', JSON.stringify(result));
                } else {
                    console.error(response.status);
                }
            };
            getLocalStorageFile(selectFile.regulatory_diagram_id);
        }
    }, [selectFile])

    useEffect(() => {
        if (dpes.length === 0) {
            setOpenAddDPE(true);
        } else {
            setOpenAddDPE(false);
        }
    }, [dpes]);

    return (
        <section className="flex w-full overflow-hidden">
            <Drawer
                open={openDrawerLeft}
                variant="persistent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                    zIndex: 1
                }}
            >
                <Box sx={{ width: '100%', height: '100%' }}>
                    <PanelGroup direction="vertical" autoSaveId>
                        <Panel defaultSize={75}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    onChange={handleChange}
                                    value={value}
                                >
                                    <Tab
                                        label="Regulatory Files"
                                        {...a11yProps(0)}
                                        sx={{
                                            padding: '5px',
                                            flex: 1,
                                            fontSize: 12
                                        }}
                                    />
                                    <Tab
                                        label="Object Components"
                                        disabled={componentDisabled}
                                        {...a11yProps(1)}
                                        sx={{
                                            padding: '5px',
                                            flex: 1,
                                            fontSize: 12,
                                            backgroundColor: componentDisabled ? 'rgba(200,200,200,0.2)' : '#fff',
                                        }}
                                    />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0} className="h-[calc(100%-49px)]">
                                <TabObjectFiles
                                    nodes={nodes}
                                    edges={edges}
                                    setOpenCreate={setOpenCreate}
                                    statusUpdate={statusUpdate}
                                    setStatusUpdate={setStatusUpdate}
                                    openEdit={openEdit}
                                    setOpenEdit={setOpenEdit}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TabComponents />
                            </TabPanel>
                        </Panel>
                        <PanelResizeHandle className="h-1 bg-slate-300 hover:bg-slate-400" />
                        <Panel defaultSize={25} minSize={20} maxSize={50} >
                            <DPEManagement isDPEUpdate={isDPEUpdate} setIsDPEUpdate={setIsDPEUpdate} />
                        </Panel>
                    </PanelGroup>
                </Box>

            </Drawer>
            <Main open={openDrawerLeft}>
                <AddDPEFirst open={openAddDPE} setOpen={setOpenAddDPE} setIsDPEUpdate={setIsDPEUpdate} />
                <ReactFlowProvider>
                    <RegulatoryDiagram nodes={nodes} setNodes={setNodes} edges={edges} setEdges={setEdges} firstCreate={firstCreate} setFirstCreate={setFirstCreate} />
                </ReactFlowProvider>
            </Main>
            <CreateRegulatoryDialog open={openCreate} setOpen={setOpenCreate} setStatusUpdate={setStatusUpdate} setFirstCreate={setFirstCreate} />
            <EditRegulatoryDialog open={openEdit} setOpen={setOpenEdit} setStatusUpdate={setStatusUpdate} />
        </section>
    )
}

export default RegulatoryPage