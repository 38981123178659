import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DialogReuse from '../../../ReuseComponents/DialogReuse';

function CreateSubjectConstraintDialog(props) {

  const theme = useTheme();
  const { open, setOpen, selectedNode, setNodes, subjectConstraints, setSubjectConstraints } = props;
  const [subjectAttribute, setSubjectAttribute] = useState([]);
  const [subjectAttributeSelected, setSubjectAttributeSelected] = useState(null);
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    setOperator(event.target.value);
  };

  const handleClose = () => {
    setSubjectAttribute([])
    setSubjectAttributeSelected()
    setOperator('')
    setValue()
    setOpen(false);
  };

  const handleGetSubjectAttribute = async () => {
    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        scopeName: "profile"
      })
    };

    const response = await fetch('https://' + process.env.REACT_APP_OP_HOST + '/iap-api/get-user-attributes', requestOptions);
    if (response.status === 200) {
      const result = await response.json();
      const arrayOfObjects = Object.entries(result).map(([key, value]) => {
        return { label: key, ...value };
      });
      setSubjectAttribute(arrayOfObjects)
    } else {
      console.error(response.status);
    }
  };

  const handleCreate = () => {
    const new_subject_constraint = `${subjectAttributeSelected}${operator}${value}`;
    let subject_constraint = [...selectedNode.data.general_properties.subject_constraint];
    subject_constraint.push(new_subject_constraint)

    let attr_type_value;
    const attr_type = subjectAttribute.map(attr => {
      if (attr.label === subjectAttributeSelected && attr.attributeType === 'string') {
        return attr_type_value = value.toString();
      } else if (attr.label === subjectAttributeSelected && attr.attributeType === 'boolean') {
        return attr_type_value = Boolean(value);
      }
    })

    const expression = {
      l_operand: {
        subject_attr: `request.subject.${subjectAttributeSelected}`
      },
      operator: operator,
      r_operand: {
        const: attr_type_value
      }
    };

    setNodes((nds) => nds.map((node) => {
      if (node.id === selectedNode.id) {
        return {
          ...node,
          data: {
            ...node.data,
            general_properties: {
              ...node.data.general_properties,
              subject_constraint: subject_constraint,
              expression: expression
            }
          }
        };
      }
      return node;
    }));
    handleClose();
  };

  const handleAddSubjectConstraint = () => {

  };

  useEffect(() => {
    if (subjectAttributeSelected && operator && value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [subjectAttributeSelected, operator, value])

  useEffect(() => {
    if (open) {
      handleGetSubjectAttribute();
    }
  }, [open])

  return (
    <DialogReuse
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth={false}
      title={"Create Subject Constraint"}
      content={
        <div
          className='px-4 py-1'
        >
          <div>
            <Stack
              direction='row'
              spacing={1}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <Autocomplete
                  id="combo-box"
                  onInputChange={(event, newInputValue) => {
                    setSubjectAttributeSelected(newInputValue);
                  }}
                  options={subjectAttribute}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  sx={{ minWidth: 200 }}
                  renderInput={(params) => <TextField {...params} label="Subject Attribute" size='small' />}
                />
              </Box>

              <div>
                <FormControl sx={{ minWidth: 150 }}>
                  <TextField
                    select
                    label="Operator"
                    size="small"
                    fullWidth
                    value={operator}
                    onChange={handleChange}
                  >
                    <MenuItem value="=">Equal (=)</MenuItem>
                    <MenuItem value="!=">Not Equal (!=)</MenuItem>
                    <MenuItem value=">">Greater than (&gt;)</MenuItem>
                    <MenuItem value="<">Less than (&lt;)</MenuItem>
                    <MenuItem value=">=">Greater than or equal to (&gt;=)</MenuItem>
                    <MenuItem value="<=">Less than or equal to (&lt;=)</MenuItem>
                  </TextField>
                </FormControl>
              </div>

              <Box
                component="form"
                autoComplete="off"
              >
                <TextField
                  id="value"
                  label="Value"
                  size='small'
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Box>

            </Stack>

            {/* {subjectConstraints.map((item, formIndex) => (
              <div key={formIndex} className='grid lg:grid-cols-3 gap-4'>
                <div>
                  <Autocomplete
                    id="combo-box"
                    onInputChange={(event, newInputValue) => {
                      setSubjectAttributeSelected(newInputValue);
                    }}
                    options={subjectAttribute}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => <TextField {...params} label="Subject Attribute" size='small' />}
                  />
                </div>
                <div>
                  <FormControl>
                    <TextField
                      select
                      label="Operator"
                      size="small"
                      fullWidth
                      value={operator}
                      onChange={handleChange}
                    >
                      <MenuItem value="=">Equal (=)</MenuItem>
                      <MenuItem value="!=">Not Equal (!=)</MenuItem>
                      <MenuItem value=">">Greater than (&gt;)</MenuItem>
                      <MenuItem value="<">Less than (&lt;)</MenuItem>
                      <MenuItem value=">=">Greater than or equal to (&gt;=)</MenuItem>
                      <MenuItem value="<=">Less than or equal to (&lt;=)</MenuItem>
                    </TextField>
                  </FormControl>
                </div>
                <div>
                  <Box
                    component="form"
                    autoComplete="off"
                  >
                    <TextField
                      id="value"
                      label="Value"
                      size='small'
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </Box>
                </div>
              </div>
            ))} */}

          </div>

          <div>
            <Button
              sx={{ minWidth: 200, marginTop: 2 }}
              variant="outlined"
              onClick={() => handleAddSubjectConstraint()}
            >
              <AddIcon />
            </Button>
          </div>

        </div>
      }
      actions={
        <Button variant="contained" disabled={disabled} onClick={() => handleCreate()}>Create</Button>
      }
    />
  )
}

export default CreateSubjectConstraintDialog