import { Alert, Autocomplete, Box, Button, Chip, Fade, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { mask } from '../../../../utils/privacy';
import { getSelectedNodeRedux, setSelectedNodeRedux } from '../../../../redux/selectedNodeSlice';
import { getRegulatoryFile } from '../../../../redux/regulatoryFileSlice';

function RegulatoryRightSide(props) {

    const {
        open,
        setNodes,
        setOpenSelectResource,
        setOpenCreateCondition,
        nodes,
        setOpenAttributeSelection,
        setOpen,
        setOpenCreateSubjectConstraint,
        setUpdateProperties
    } = props;
    const [label, setLabel] = useState("");
    const [description, setDescription] = useState("");
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [resourceLabel, setResourceLabel] = useState("");
    const [conditions, setConditions] = useState([]);
    const [resource, setResource] = useState("");
    const [attributeSelection, setAttributeSelection] = useState([]);
    const dispatch = useDispatch();
    const [privacies, setPrivacies] = useState([]);
    const [dpeConfig, setDpeConfig] = useState();
    const [attributePrivacy, setAttributePrivacy] = useState([]);
    const selectedNode = useSelector(getSelectedNodeRedux);
    const [subjectContraint, setSubjectContraint] = useState([]);

    useEffect(() => {
        const dpeConfig = JSON.parse(localStorage.getItem('dpe-config'));
        if (dpeConfig) {
            setDpeConfig(dpeConfig);
        }
    }, []);

    const handleOpenCreateCondition = () => {
        setOpenCreateCondition(true);
    };

    const onChangeLabel = (value) => {
        setLabel(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === selectedNode.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            label: value,
                        }
                    },
                };
            }
            return node;
        }));
    };

    const onChangeDescription = (value) => {
        setDescription(value)
        setNodes((nds) => nds.map((node) => {
            if (node.id === selectedNode.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            description: value
                        }
                    }
                };
            }
            return node;
        }));
    };

    // const handleSetAttributeSelection = (attrs) => {
    //     const arr_attr = attrs.map(item => item.name);
    //     const arraysAreEqual = JSON.stringify(arr_attr) === JSON.stringify(nodeSelected?.data?.general_properties?.selection);
    //     if (!arraysAreEqual && nodeSelected.type === 'selection') {
    //         setNodes((nds) => nds.map((node) => {
    //             if (node.id === nodeSelected.id) {
    //                 return {
    //                     ...node,
    //                     data: {
    //                         ...node.data,
    //                         general_properties: {
    //                             ...node.data.general_properties,
    //                             selection: arr_attr
    //                         }
    //                     }
    //                 };
    //             }
    //             return node;
    //         }));
    //     }
    // };

    const handleClick = (event) => {
        setOpenSelectResource(true);
        event.preventDefault();
        event.stopPropagation();
    };

    const handleGetResource = () => {
        const root_node = nodes.find((node) => node.type === "root");
        const is_resource = root_node?.data?.general_properties?.resource;
        if (is_resource) {
            setResourceLabel(is_resource);
        } else {
            setResourceLabel("-");
        }
    };

    const handleOpenAttributeSelection = () => {
        setOpenAttributeSelection(true);
    };

    const handleAddPrivacy = useCallback(() => {
        const initialPrivacy = {
            privacy_id: uuidv4(),
            method: 'masking',
            attribute: {
                name: ""
            },
            position: 'left',
            symbol: '*',
            visible_length: 3,
            error: false,
            error_text: []
        }
        const new_arr = [...privacies, initialPrivacy];
        setPrivacies(new_arr);
        onUpdatePrivacy(new_arr);
    }, [privacies]);

    const handleDeletePrivacy = (id) => {
        const privacy_deleted = privacies.filter((item) => item.privacy_id !== id);
        setPrivacies(privacy_deleted);
        onUpdatePrivacy(privacy_deleted);
    };

    const handleInputChange = (id, key, value) => {
        const updatePrivacys = privacies.map((privacy) => {
            if (privacy?.privacy_id === id) {
                if (key === 'attribute' && value) {
                    const newValue = {
                        ...value,
                        checked: true,
                    };
                    return {
                        ...privacy,
                        [key]: newValue
                    }
                } else if (key === 'attribute' && !value) {
                    return {
                        ...privacy,
                        [key]: {
                            name: ""
                        },
                    }
                } else {
                    return {
                        ...privacy,
                        [key]: value
                    }
                }
            }
            return privacy;
        });
        setPrivacies(updatePrivacys);
        handleUpdatePrivacyNode(updatePrivacys);
    };

    const handleValidatePrivacyError = async (data) => {
        const validatedPrivacyConfig = data.map((privacy) => {
            if (privacy?.attribute?.name !== "" && privacy?.visible_length !== "") {
                return {
                    ...privacy,
                    error: false,
                    error_text: []
                }
            } else {
                return {
                    ...privacy,
                    error: true,
                    error_text: ["Please complete all required fields."]
                }
            }
        });
        return validatedPrivacyConfig;
    };

    const handleUpdatePrivacyNode = async (update) => {

        const isValidated = await handleValidatePrivacyError(update);
        const hasError = isValidated.some(obj => obj.error === true);

        setNodes((nds) => nds.map((node) => {
            if (node.id === selectedNode.id && selectedNode.type === 'privacy') {
                if (hasError) {
                    const node_privacy = {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                privacies: isValidated
                            },
                            error: true,
                            error_text: ["Privacy configuration for the data is missing."]
                        }
                    }
                    dispatch(setSelectedNodeRedux(node_privacy));
                    return node_privacy;
                } else {
                    const node_privacy = {
                        ...node,
                        data: {
                            ...node.data,
                            general_properties: {
                                ...node.data.general_properties,
                                privacies: isValidated
                            },
                            error: false,
                            error_text: []
                        }
                    }
                    dispatch(setSelectedNodeRedux(node_privacy));
                    return node_privacy;
                }
            }
            return node;
        }));

    };

    const onUpdatePrivacy = (new_arr) => {
        setNodes((nds) => nds.map((node) => {
            if (node.id === selectedNode.id && selectedNode.type === 'privacy') {
                const node_privacy = {
                    ...node,
                    data: {
                        ...node.data,
                        general_properties: {
                            ...node.data.general_properties,
                            privacies: new_arr
                        }
                    }
                }
                return node_privacy;
            }
            return node;
        }));
        setUpdateProperties(true);
    };

    const handleOpenCreateSubjectConstraint = () => {
        setOpenCreateSubjectConstraint(true);
    };

    useEffect(() => {
        if (regulatoryFile) {
            setOpen(false);
        }
    }, [regulatoryFile])

    useEffect(() => {
        if (selectedNode) {
            const { type, selected, data } = selectedNode;
            setLabel(data.general_properties.label);
            setDescription(data.general_properties.description);
            if (type === 'subject' && selected) {
                setSubjectContraint(data?.general_properties?.subject_constraint);
            } else if (type === "root" && selected) {
                const resourceLabel = data?.general_properties?.resource;
                setResourceLabel(resourceLabel || '-');
            } else if (type === "condition" && selected) {
                setConditions(data?.general_properties?.conditions);
            } else if (type === "selection" && selected) {
                if (data.general_properties.attribute_selection) {
                    handleGetResource();
                    setAttributeSelection(data.general_properties.attribute_selection);
                }
            } else if (type === "privacy" && selected) {
                setAttributePrivacy(data?.general_properties?.attribute_selection);
                setPrivacies(data?.general_properties?.privacies);
            } else {
                setPrivacies([]);
            }
        }
    }, [selectedNode])

    return (
        <Box
            style={{
                // flex: 1,
                // overflowY: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                padding: '20px 15px 60px 15px',
                // display: 'flex',
                // flexDirection: 'column',
            }}
        >
            {open ? (
                <>
                    <Typography>General Properties</Typography>
                    <TextField
                        required
                        id="component-label"
                        label="Label"
                        fullWidth
                        size='small'
                        margin="normal"
                        value={label}
                        onChange={(e) => onChangeLabel(e.target.value)}
                    />
                    <TextField
                        id="description"
                        label="Description"
                        fullWidth
                        size='small'
                        multiline
                        rows={3}
                        margin="normal"
                        value={description}
                        onChange={(e) => onChangeDescription(e.target.value)}
                    />
                    {selectedNode?.type === "subject" &&
                        <>
                            <Typography sx={{ marginTop: 2 }} variant='subtitle2'>Subject Constraint</Typography>
                            <div>
                                <List sx={{ padding: '0px' }}>
                                    {subjectContraint.map((item, index) => {
                                        return (
                                            <ListItem
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    margin: '10px 0px',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                                key={index}
                                                secondaryAction={
                                                    <IconButton aria-label="more">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <Chip
                                                    label={item}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                            <Button
                                sx={{
                                    marginTop: 2,
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleOpenCreateSubjectConstraint()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </>
                    }
                    {selectedNode?.type === "root" &&
                        <>
                            <FormControl sx={{ minWidth: 80, width: '100%', marginTop: 2 }}>
                                <TextField
                                    label="Resource"
                                    id="resource-label"
                                    fullWidth
                                    size="small"
                                    value={resourceLabel || '-'}
                                    onMouseDown={handleClick}
                                    error={selectedNode?.data?.error_dpe}
                                >
                                </TextField>
                            </FormControl>
                        </>
                    }
                    {(selectedNode?.type === "condition" && selectedNode?.data?.general_properties?.attribute_selection) &&
                        <>
                            <Typography sx={{ marginTop: 2 }} variant='subtitle2'>Condition</Typography>
                            <div>
                                <List sx={{ padding: '0px' }}>
                                    {conditions.map((item, index) => {
                                        return (
                                            <ListItem
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    margin: '10px 0px',
                                                    padding: '10px 0px 10px 10px',
                                                    borderRadius: '5px'
                                                }}
                                                key={index}
                                                secondaryAction={
                                                    <IconButton aria-label="more">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <Chip
                                                    label={item.display}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                            <Button
                                sx={{
                                    marginTop: 2,
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleOpenCreateCondition()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </>
                    }
                    {(selectedNode?.type === "selection" && selectedNode?.data?.general_properties?.attribute_selection) &&
                        <>
                            <Typography mt={2} mb={1} variant='subtitle2'>Attribute Selection</Typography>
                            <div>
                                <Stack
                                    sx={{
                                        width: '100%',
                                        border: '1px solid #ccc',
                                        borderTopLeftRadius: '5px',
                                        borderTopRightRadius: '5px',
                                        paddingLeft: '10px',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                    direction='row'
                                    justifyContent="space-between"
                                >
                                    <Box display="flex">
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="primary"
                                            noWrap
                                            sx={{
                                                marginRight: '5px',
                                            }}
                                        >
                                            Resource:
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="black"
                                            noWrap
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                // maxWidth: '40%'
                                                maxWidth: '155px'
                                            }}
                                        >
                                            {resourceLabel}
                                        </Typography>
                                    </Box>
                                    <div>
                                        <IconButton onClick={handleOpenAttributeSelection}>
                                            <EditNoteIcon />
                                        </IconButton>
                                    </div>
                                </Stack>
                                <div
                                    style={{
                                        border: '1px solid #ccc',
                                        borderBottomLeftRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                        maxHeight: 'calc(100vh - 390px)',
                                        overflow: 'auto'
                                    }}
                                >
                                    <List sx={{ padding: '0px' }}>
                                        {attributeSelection.map((item, index) => {
                                            if (item.checked) {
                                                return (
                                                    <ListItem
                                                        sx={{
                                                            padding: '5px 0px 5px 10px',
                                                        }}
                                                        key={index}
                                                    >
                                                        {item.name}
                                                    </ListItem>
                                                )
                                            }
                                        })}
                                    </List>
                                </div>
                            </div>
                        </>
                    }
                    {(selectedNode?.type === "privacy" && selectedNode?.data?.general_properties?.attribute_selection) &&
                        <Box
                            sx={{
                                flex: 1,
                                overflowY: 'auto', // เปิด scroll แนวตั้ง
                                paddingBottom: '16px',
                            }}
                        >
                            <Typography mt={2} mb={2} variant='subtitle2'>Privacy</Typography>
                            {privacies.map((item, index) => {
                                return (
                                    <Fade in={true} timeout={500} key={index}>
                                        <Box
                                            sx={{
                                                mb: 2,
                                            }}
                                        >
                                            <Stack
                                                sx={{
                                                    width: '100%',
                                                    border: '1px solid #ccc',
                                                    borderTopLeftRadius: '5px',
                                                    borderTopRightRadius: '5px',
                                                    px: '14px',
                                                    py: '6px',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                                direction='row'
                                                justifyContent="space-between"
                                            >
                                                <Box className="flex flex-row items-center gap-2">
                                                    <Typography variant="caption" color="primary">Method: </Typography>
                                                    <FormControl variant="standard" size='small' sx={{ fontSize: 14, mt: '5px' }}>
                                                        <Select
                                                            labelId="select-method"
                                                            id="method"
                                                            name="method"
                                                            value={item.method}
                                                            sx={{ fontSize: 12 }}
                                                            label="method"
                                                            onChange={(e) => handleInputChange(item.privacy_id, 'method', e.target.value)}
                                                        >
                                                            <MenuItem value='masking'>Masking</MenuItem>
                                                            <MenuItem value='encrypt'>Encrypt</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <IconButton sx={{ width: 24, height: 24 }} onClick={() => handleDeletePrivacy(item.privacy_id)}>
                                                    <DeleteIcon sx={{ width: 22, height: 22 }} className='text-gray-400 hover:text-red-800' />
                                                </IconButton>

                                            </Stack>
                                            <div
                                                style={{
                                                    border: '1px solid #ccc',
                                                    borderBottomLeftRadius: '5px',
                                                    borderBottomRightRadius: '5px',
                                                    padding: '14px'
                                                }}
                                            >
                                                <Autocomplete
                                                    name="attribute"
                                                    fullWidth
                                                    size='small'
                                                    id="attribute-select"
                                                    options={attributePrivacy}
                                                    value={item.attribute}
                                                    onChange={(event, newValue) => {
                                                        handleInputChange(item.privacy_id, 'attribute', newValue)
                                                    }}
                                                    getOptionLabel={(option) => option.name}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        label="Attribute"
                                                        error={item?.error}
                                                        helperText={item?.error_text}
                                                    />}
                                                />
                                                <Box sx={{ marginTop: 2 }}>
                                                    <TextField
                                                        type="number"
                                                        name="visible_length"
                                                        label="Visible length"
                                                        size='small'
                                                        inputProps={{ min: 0 }}
                                                        value={item.visible_length}
                                                        onChange={(e) => {
                                                            const min = 0;
                                                            var value = parseInt(e.target.value);
                                                            console.log("value", e.target.value);
                                                            if (value < min) value = min;
                                                            if (!value) value = min;
                                                            handleInputChange(item.privacy_id, 'visible_length', value);
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={item?.error}
                                                        helperText={item?.error_text}
                                                    />
                                                </Box>
                                                <Stack direction='row' spacing={1} mt={2}>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="select-position">Symbol</InputLabel>
                                                        <Select
                                                            labelId="select-symbol"
                                                            id="select-symbol"
                                                            name="symbol"
                                                            value={item.symbol}
                                                            label="Symbol"
                                                            onChange={(e) => handleInputChange(item.privacy_id, 'symbol', e.target.value)}
                                                        >
                                                            <MenuItem value='*'>*</MenuItem>
                                                            <MenuItem value='#'>#</MenuItem>
                                                            <MenuItem value='$'>$</MenuItem>
                                                            <MenuItem value='@'>@</MenuItem>
                                                            <MenuItem value='?'>?</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel id="select-position">Position</InputLabel>
                                                        <Select
                                                            labelId="select-position"
                                                            id="select-position"
                                                            name="position"
                                                            value={item.position}
                                                            label="Position"
                                                            onChange={(e) => handleInputChange(item.privacy_id, 'position', e.target.value)}
                                                        >
                                                            <MenuItem value='left'>Left</MenuItem>
                                                            <MenuItem value='right'>Right</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Stack>
                                                <Alert icon={<PreviewIcon fontSize="inherit" />} className='mt-3' severity="info">
                                                    {mask("Preview Message", Number(item.visible_length), item.symbol, item.position)}
                                                </Alert>
                                            </div>
                                        </Box>
                                    </Fade>
                                )
                            })}
                            <Button
                                sx={{
                                    border: '1px solid #ccc',
                                }}
                                variant="outlined"
                                fullWidth
                                onClick={() => handleAddPrivacy()}
                            >
                                <AddIcon color='action' />
                            </Button>
                        </Box>
                    }
                </>
            ) : (
                <>
                    <Typography>Regulatory Workspace</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Regulatory Name</Typography>
                    <Typography>{regulatoryFile?.regulatory_diagram_name}</Typography>
                    <Typography mt={3} variant="caption" display="block" color="grey.500">Description</Typography>
                    <Typography>{regulatoryFile?.description}</Typography>
                </>
            )}
        </Box >
    )
}

export default RegulatoryRightSide