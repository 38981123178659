import { Box, Drawer, styled, Tab, Tabs } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getOpenDrawerLeft } from '../../../../redux/drgMgtSlice';
import { ReactFlowProvider } from '@xyflow/react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import DPEManagement from '../../../../components/DPEManagement';
import PropTypes from 'prop-types';
import RegulatoryFiles from './components/RegulatoryFiles';
import CreateRegulatoryDialog from './components/CreateRegulatoryDialog';
import RegulatoryDiagram from './components/RegulatoryDiagram';
import RegulatoryComponents from './components/RegulatoryComponents';
import { getRegulatoryFile, setRegulatoryFile } from '../../../../redux/regulatoryFileSlice';

const drawerWidth = 250;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.only('xs')]: {
            marginLeft: 0,
        },
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ padding: '0px', height: "100%" }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabRegulatoryFiles = memo(({ nodes, edges, setOpenCreate, statusUpdate, setStatusUpdate }) => {
    return <>
        <RegulatoryFiles nodes={nodes} edges={edges} setOpenCreate={setOpenCreate} statusUpdate={statusUpdate} setStatusUpdate={setStatusUpdate} />
    </>;
});

const TabComponents = memo(() => {
    return <RegulatoryComponents />;
});

function RegulatoryPage() {

    const openDrawerLeft = useSelector(getOpenDrawerLeft);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [componentDisabled, setComponentDisabled] = useState(true);
    const [openCreate, setOpenCreate] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const regulatoryFile = useSelector(getRegulatoryFile);
    const [selectFile, setSelectFile] = useState();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (regulatoryFile) {
            setComponentDisabled(false);
        }
    }, [regulatoryFile])

    useEffect(() => {
        const selectFile = JSON.parse(localStorage.getItem('regulatory-file'));
        if (selectFile) {
            setSelectFile(selectFile);
        }
    }, []);

    useEffect(() => {
        if (selectFile?.regulatory_diagram_id) {
            const getLocalStorageFile = async (regulatory_diagram_id) => {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/regulatory-diagram/get-regulatory/${regulatory_diagram_id}`);
                if (response.ok) {
                    const result = await response.json();
                    dispatch(setRegulatoryFile(result));
                    localStorage.setItem('regulatory-file', JSON.stringify(result));
                } else {
                    console.error(response.status);
                }
            };
            getLocalStorageFile(selectFile.regulatory_diagram_id);
        }
    }, [selectFile])

    return (
        <section className="flex w-full overflow-hidden">
            <Drawer
                open={openDrawerLeft}
                variant="persistent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginLeft: "70px", marginTop: "52px" },
                    zIndex: 1
                }}
            >
                <Box sx={{ width: '100%', height: '100%' }}>
                    <PanelGroup direction="vertical" autoSaveId>
                        <Panel defaultSize={75}>
                            <Tabs
                                onChange={handleChange}
                                value={value}
                                aria-label="tab-regulatory"
                            >
                                <Tab
                                    label="Regulatory Files"
                                    {...a11yProps(0)}
                                    sx={{
                                        padding: '12px',
                                        flex: 1,
                                        fontSize: 12
                                    }}
                                />
                                <Tab
                                    label="Components"
                                    disabled={componentDisabled}
                                    {...a11yProps(1)}
                                    sx={{
                                        padding: '12px',
                                        flex: 1,
                                        backgroundColor: componentDisabled ? 'rgba(200,200,200,0.2)' : '#fff',
                                    }}
                                />
                            </Tabs>
                            <CustomTabPanel value={value} index={0} className="h-[calc(100%-48px)]">
                                <TabRegulatoryFiles nodes={nodes} edges={edges} setOpenCreate={setOpenCreate} statusUpdate={statusUpdate} setStatusUpdate={setStatusUpdate} />
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <TabComponents />
                            </CustomTabPanel>
                        </Panel>
                    </PanelGroup>
                </Box>

            </Drawer>
            <Main open={openDrawerLeft} >
                <ReactFlowProvider>
                    <RegulatoryDiagram nodes={nodes} setNodes={setNodes} edges={edges} setEdges={setEdges} />
                </ReactFlowProvider>
            </Main>
            <CreateRegulatoryDialog open={openCreate} setOpen={setOpenCreate} setStatusUpdate={setStatusUpdate} />
        </section>
    )
}

export default RegulatoryPage