import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    ReactFlow,
    addEdge,
    MiniMap,
    Controls,
    Background,
    ControlButton,
    useReactFlow,
    MarkerType,
    applyNodeChanges,
    applyEdgeChanges,
} from '@xyflow/react';
import dagre from 'dagre';
import '@xyflow/react/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import SubjectRightSide from './SubjectRightSide';
import CreateSubjectConstraintDialog from './CreateSubjectConstraintDialog';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Button, CircularProgress, Drawer, Tooltip, Typography, useTheme } from '@mui/material';
import { getOpenRightSide, setOpenRightSide } from '../../../../../redux/openRightSideSlice';
import { getSubjectFile } from '../../../../../redux/subjectFileSlice';
import SubjectNode from './SubjectNode';
import ResponseDialog from '../../../ReuseComponents/ConfirmDialogReuse';
import { LoadingButton } from '@mui/lab';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ConfirmDialogReuse from '../../../ReuseComponents/ConfirmDialogReuse';

const nodeTypes = {
    subject: SubjectNode,
};

const nodeWidth = 70;
const nodeHeight = 100;

const getLayoutedElements = (nodes, edges) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'TB' });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };
    });

    return { nodes, edges };
};

const initialNodes = [
    {
        id: uuidv4(),
        type: 'subject',
        root: true,
        position: { x: 0, y: 0, zoom: 1 },
        data: {
            onAddNode: () => { },
            general_properties: {
                label: '',
                description: '',
                subject_constraint: []
            }
        },
    },
];

function SubjectDiagram(props) {

    const { nodes, setNodes, edges, setEdges } = props;
    const subjectFile = useSelector(getSubjectFile);
    const dispatch = useDispatch();
    const theme = useTheme();
    const openRightSide = useSelector(getOpenRightSide);
    // const [nodes, setNodes] = useState([]);
    // const [edges, setEdges] = useState([]);
    const [nodeSelected, setNodeSelected] = useState();
    const [openProperties, setOpenProperties] = useState(false);
    const [openCreateSubjectConstraint, setOpenCreateSubjectConstraint] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSave, setOpenSave] = useState(false);
    const [openResponse, setOpenResponse] = useState(false);
    const [responseStatus, setResponseStatus] = useState("");
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [subjectConstraints, setSubjectConstraints] = useState([]);
    const [showMinimap, setShowMinimap] = useState(false);

    const {
        setViewport,
        getViewport,
        fitView,
        zoomTo,
        getNodes,
        getEdges
    } = useReactFlow();

    const drawerWidth = 300;

    const [profile, setProfile] = useState();

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem('iaam'));
        if (profile) {
            setProfile(profile);
        }
    }, []);

    const saveViewportState = useCallback(() => {
        const viewport = getViewport();
        const all_nodes = getNodes();
        const all_edges = getEdges();
        const viewport_flow = {
            nodes: all_nodes,
            edges: all_edges,
            viewport: viewport
        }
        const allFlows = JSON.parse(localStorage.getItem('subject-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('subject-file')) || {};
        allFlows[currentFile.subject_diagram_id] = viewport_flow;
        localStorage.setItem('subject-diagram', JSON.stringify(allFlows));
    }, [getViewport]);

    const restoreFlowState = (node_data, edge_data) => {
        const allFlows = JSON.parse(localStorage.getItem('subject-diagram')) || {};
        const currentFile = JSON.parse(localStorage.getItem('subject-file')) || {};
        const flowState = allFlows[currentFile.subject_diagram_id];
        if (flowState) {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                setViewport(flowState.viewport || { x: 0, y: 0, zoom: 1 });
                setLoading(false);
            }, 500);
        } else {
            setTimeout(() => {
                setNodes(node_data);
                setEdges(edge_data);
            }, 300);
            setTimeout(() => {
                fitView();
                zoomTo(1, { duration: 0 });
                setLoading(false);
            }, 500);
        }

    };

    useEffect(() => {
        if (subjectFile) {
            setNodeSelected(undefined);
            console.log('subjectFile: ', subjectFile);
            if (subjectFile.data && subjectFile?.data?.nodes.length !== 0) {
                setLoading(true);
                restoreFlowState(subjectFile.data.nodes, subjectFile.data.edges);
            } else {
                setLoading(true);
                restoreFlowState(initialNodes, []);
            }
        }
    }, [subjectFile])

    const onPaneClick = () => {
        setNodeSelected(undefined);
        setOpenProperties(false);
    };

    const onNodeClick = (event, node) => {
        setNodeSelected(node);
        setOpenProperties(true);
    };

    const onAddNode = useCallback((parentNode) => {
        const newNodeId = uuidv4();
        const newNode = {
            id: newNodeId,
            type: 'subject',
            position: { x: parentNode.position.x, y: parentNode.position.y + 100 },
            data: {
                onAddNode: () => onAddNode(newNode),
                general_properties: {
                    label: '',
                    description: '',
                    subject_constraint: []
                }
            }
        };

        const newEdge = {
            id: `${parentNode.id}-${newNodeId}`,
            source: parentNode.id,
            sourceHandle: "bottom-source",
            target: newNodeId,
            targetHandle: "top-target",
            style: { stroke: 'rgba(0,0,0,0.7)', strokeDasharray: '5,5' }, // เส้นประ
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
            },
        };

        const newNodes = [...nodes, newNode];
        const newEdges = [...edges, newEdge];

        const layoutedElements = getLayoutedElements(newNodes, newEdges);

        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);
    }, [nodes, edges]);

    const nodesWithUpdatedData = useMemo(() => {
        return nodes.map((node) => {
            if (node.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        onAddNode: () => onAddNode(node),
                        isSelected: node.id === nodeSelected?.id,
                    },
                };
            }
            return node;
        });
    }, [nodes, onAddNode, nodeSelected]);

    const handleSaveDiagram = () => {
        setOpenSave(true);
    };

    const onNodesChange = useCallback(
        async (changes) => {
            setNodes((nodes) => {
                const oldNodes = nodes.map(
                    (node) => ({
                        ...node,
                        measured: {
                            width: node.measured?.width || 150,
                            height: node.measured?.height || 40,
                        }
                    })
                );
                return applyNodeChanges(changes, oldNodes);
            });
        },
        [setNodes],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges],
    );

    // const onNodeDragStop = useCallback(() => {
    //     console.log("onNodeDragStop")
    // }, [nodes, edges]);

    const onNodeDoubleClick = useCallback(() => {
        if (openRightSide) {
            dispatch(setOpenRightSide(openRightSide));
        } else {
            dispatch(setOpenRightSide(!openRightSide));
        }
    }, [nodes, edges]);

    // const onEdgeUpdateEnd = useCallback(() => {
    //     console.log("onEdgeUpdateEnd")
    // }, [nodes, edges]);

    const handleConfirm = async () => {
        setLoadingDialog(true);
        const now = Date.now();
        const update = {
            subject_diagram_id: subjectFile.subject_diagram_id,
            subject_diagram_name: subjectFile.subject_diagram_name,
            creator_id: subjectFile.creator_id,
            creator_name: subjectFile.creator_name,
            last_update_id: profile.uprofile.inum,
            last_update_name: profile.uprofile.displayName,
            create_timestamp: subjectFile.create_timestamp,
            update_timestamp: now,
            description: subjectFile.description,
            complete: true,
            data: {
                nodes: nodes,
                edges: edges
            }
        }

        const requestOptions = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        };

        const response = await fetch(process.env.REACT_APP_API_URL + `/subject-diagram/update-subject/${subjectFile.subject_diagram_id}`, requestOptions);
        if (response.status === 200) {
            setLoadingDialog(false);
            setOpenSave(false);
            setResponseStatus("Subject diagram updated successfully");
            setOpenResponse(true);
        } else {
            console.error(response.status);
            setLoadingDialog(false);
            setResponseStatus(`Error status: ${response.status}`);
            setOpenResponse(true);
        }
    };

    const handleCancel = () => {
        setOpenSave(false);
    };

    const onResponseClose = () => {
        setOpenResponse(false);
    };

    const toggleMinimap = () => {
        setShowMinimap(!showMinimap);
    };

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({
            ...params,
        }, eds)),
        [setEdges],
    );

    const edgesWithUpdatedData = useMemo(() => {
        return edges.map((edge) => {
            return {
                ...edge,
            };
        });
    }, [edges]);

    return (
        <div className='h-[calc(100vh-64px)] relative'>
            {subjectFile &&
                <>
                    <ReactFlow
                        nodes={nodesWithUpdatedData}
                        edges={edgesWithUpdatedData}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        onNodeClick={onNodeClick}
                        onPaneClick={onPaneClick}
                        onMoveEnd={saveViewportState}
                        // onNodeDragStop={onNodeDragStop}
                        onNodeDoubleClick={onNodeDoubleClick}
                    // onEdgeUpdateEnd={onEdgeUpdateEnd}
                    // fitView
                    >
                        {showMinimap && <MiniMap style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'none',
                            width: 200,
                            border: '1px solid #ccc',
                        }} />}
                        <Controls position="top-left">
                            <ControlButton onClick={() => handleSaveDiagram()}>
                                <Tooltip
                                    title="save"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    <SaveIcon />
                                </Tooltip>
                            </ControlButton>
                            <ControlButton onClick={() => toggleMinimap()}>
                                <Tooltip
                                    title="minimap"
                                    arrow
                                    PopperProps={{
                                        disablePortal: true, // ทำให้ Tooltip สามารถแสดงนอก layout
                                    }}
                                    placement="right"
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                >
                                    {showMinimap ? (
                                        <LayersIcon />
                                    ) : (
                                        <LayersClearIcon />
                                    )}
                                </Tooltip>
                            </ControlButton>
                        </Controls>
                        <Background />
                    </ReactFlow>
                    <Drawer
                        open={openRightSide}
                        anchor="right"
                        variant="persistent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', marginTop: "52px" },
                        }}
                    >
                        <SubjectRightSide
                            nodeSelected={nodeSelected}
                            open={openProperties}
                            setOpen={setOpenProperties}
                            setOpenCreateSubjectConstraint={setOpenCreateSubjectConstraint}
                            setNodes={setNodes}
                            subjectConstraints={subjectConstraints}
                            setSubjectConstraints={setSubjectConstraints}
                        />
                    </Drawer>
                    <CreateSubjectConstraintDialog
                        open={openCreateSubjectConstraint}
                        setOpen={setOpenCreateSubjectConstraint}
                        setNodes={setNodes}
                        nodeSelected={nodeSelected}
                        subjectConstraints={subjectConstraints}
                        setSubjectConstraints={setSubjectConstraints}
                    />
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: 1,
                            backgroundColor: 'rgba(220, 220, 220, 1)',
                            position: 'absolute'
                        }}
                        open={loading}
                        onClick={() => setLoading(false)}
                    >
                        <CircularProgress />
                    </Backdrop>
                    <ConfirmDialogReuse
                        open={openSave}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">Are you sure you want to save this diagram?</Typography>
                            </div>
                        }
                        actions={
                            <div className='grid grid-cols-2 gap-2'>
                                <LoadingButton
                                    variant="contained"
                                    disabled={loadingDialog}
                                    loading={loadingDialog}
                                    onClick={() => handleConfirm()}
                                >
                                    Confirm
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </div>
                        }
                    />
                    <ResponseDialog
                        open={openResponse}
                        maxWidth={"sm"}
                        content={
                            <div>
                                <Typography variant="subtitle1">{responseStatus}</Typography>
                            </div>
                        }
                        actions={
                            <Button
                                variant="outlined"
                                onClick={() => onResponseClose()}
                            >
                                Close
                            </Button>
                        }
                    />
                </>
            }
        </div>
    );
}

export default SubjectDiagram