import { configureStore } from '@reduxjs/toolkit'
import iaamSlice from './iaamSlice'
import drgMgtSlice from './drgMgtSlice'
import subjectFileSlice from './subjectFileSlice'
import openRightSideSlice from './openRightSideSlice'
import objectFileSlice from './objectFileSlice'
import dpeConfigSlice from './dpeConfigSlice'
import attributeSelectionSlice from './attributeSelectionSlice'
import regulatoryFileSlice from './regulatoryFileSlice'
import dpesSlice from './dpesSlice'
import selectedNodeSlice from './selectedNodeSlice'
import regulatoryFileActionSlice from './regulatoryFileActionSlice'

export const store = configureStore({
  reducer: {
    iaamContext: iaamSlice,
    drgMgt: drgMgtSlice,
    subjectFile: subjectFileSlice,
    openRightSide: openRightSideSlice,
    objectFile: objectFileSlice,
    dpeConfig: dpeConfigSlice,
    attributeSelection: attributeSelectionSlice,
    regulatoryFile: regulatoryFileSlice,
    dpeRedux: dpesSlice,
    selectedNode: selectedNodeSlice,
    regulatoryFileAction: regulatoryFileActionSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})