exports.mask = (value, visible_length, symbol, position) => {
    let mask_symbo = symbol? symbol : "*";
    let mask_value = String(value);
    let _visible_length = visible_length? visible_length : 3;
    let mask_position = ['left', 'right'].includes(position)? position : "left";

    if(mask_position==='left') {
        return mask_value.length > _visible_length ? `${mask_symbo.repeat(mask_value.length-_visible_length)}${mask_value.substring(mask_value.length-(_visible_length), mask_value.length)}` : `${mask_symbo.repeat(mask_value.length)}`
    }
    else return mask_value.length > _visible_length ? `${mask_value.substring(0, _visible_length)}${mask_symbo.repeat(mask_value.length-(_visible_length))}` : `${mask_symbo.repeat(mask_value.length)}`
}