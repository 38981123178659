import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ProjectPage from './ProjectPage';
import ProjectWorkSpace from './ProjectWorkSpace';
import RegulatoryPage from './Regulatory/RegulatoryPage';

function EditorPage() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/editor') {
            navigate("projects");
        }
    }, [location])

    return (
        <>
            <Routes>
                <Route path="projects" element={<ProjectPage />} />
                <Route path="workspace" element={<ProjectWorkSpace />} />
                <Route path="regulatory" element={<RegulatoryPage />} />
                <Route path="/" element={<ProjectPage />} />
            </Routes>
        </>
    )
}

export default EditorPage