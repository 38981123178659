import { createSlice } from '@reduxjs/toolkit'

export const regulatoryFileActionSlice = createSlice({
    name: 'regulatoryFileAction',
    initialState: {
        result: undefined,
    },
    reducers: {
        setRegulatoryFileAction: (state, action) => {
            state.result = action.payload;
        },
        clearRegulatoryFileAction: (state) => {
            state.result = undefined;
        }
    },
})

export const { setRegulatoryFileAction, clearRegulatoryFileAction } = regulatoryFileActionSlice.actions;

export const getRegulatoryFileAction = (state) => state.regulatoryFileAction.result;

export default regulatoryFileActionSlice.reducer