import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react'
import LockIcon from '@mui/icons-material/Lock';

function ObjectComponents() {

    const theme = useTheme();

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <aside>
            <div style={{ padding: '10px 5px' }}>
                <Stack direction='row' mt={1}>
                    <div style={{
                        flex: 1,
                        textAlign: 'center',
                    }}>
                        <div
                            style={{
                                width: '85px',
                                height: '32px',
                                border: '1px solid #1976d2',
                                borderRadius: '5px',
                                margin: 'auto',
                                backgroundColor: 'rgba(25, 118, 210, 0.3)',
                            }}
                            onDragStart={(event) => onDragStart(event, 'condition')}
                            draggable
                        >
                        </div>
                        <Typography mt={1} variant="caption" display="block">Condition</Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <div
                            className={`h-32px flex justify-center items-center`}
                            onDragStart={(event) => onDragStart(event, 'selection')}
                            draggable
                        >
                            <svg width={85} height={32} >
                                <g>
                                    <path d={`M 0 15 L 10 0 L 75 0 L 85 15 L 75 30 L 10 30 Z`}
                                        fill="rgba(25, 118, 210, 0.3)"
                                        strokeWidth="1"
                                        stroke={theme.palette.primary.main}>
                                    </path>
                                </g>
                            </svg>
                        </div>
                        <Typography mt={1} variant="caption" display="block">Selection</Typography>
                    </div>
                </Stack>
                <Stack direction='row' mt={2}>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <Stack
                            direction='row'
                            sx={{
                                width: '85px',
                                height: '32px',
                                border: '1px solid #1976d2',
                                borderRadius: '5px',
                                margin: 'auto',
                                backgroundColor: 'rgba(25, 118, 210, 0.3)',
                            }}
                            onDragStart={(event) => onDragStart(event, 'privacy')}
                            draggable
                        >
                            <div
                                style={{
                                    width: '30%',
                                    borderRight: '1px solid #1976d2',
                                }}
                            >
                                <LockIcon color="primary" sx={{ width: 15, height: 15 }} />
                            </div>
                            <div
                                style={{
                                    width: '85px',
                                }}
                            ></div>
                        </Stack>
                        <Typography mt={1} variant="caption" display="block">Privacy</Typography>
                    </div>
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <div
                            style={{
                                width: '85px',
                                height: '32px',
                                border: '1px dashed #1976d2',
                                borderRadius: '5px',
                                margin: 'auto',
                                backgroundColor: 'rgba(25, 118, 210, 0.3)',
                            }}
                            onDragStart={(event) => onDragStart(event, 'conjunction')}
                            draggable
                        >
                        </div>
                        <Typography mt={1} variant="caption" display="block">Conjunction</Typography>
                    </div>
                </Stack>
            </div >
        </aside >
    )
}

export default ObjectComponents