import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Chip, CircularProgress, Divider, Stack, TextField } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';


export default function EditDPEDialog(props) {
    const { open, dpeSeleted, setOpen, setIsDPEUpdate } = props;

    const dpeInit = { url: dpeSeleted.issuer, conf: {} }
    const theme = useTheme();
    const [validDPE, setValidDPE] = useState(false);
    const [host, setHost] = useState("");
    const [dpeDiscovery, setDPEDiscovery] = useState(dpeInit);
    const [error, setError] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [isDuplicate, setIsDuplicate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [project, setProject] = useState();

    useEffect(() => {
        if(dpeSeleted.issuer){
            setHost(dpeSeleted.issuer.replace("https://", ""))
            const project = JSON.parse(localStorage.getItem('project'));
            if (project) {
                setProject(project);
            }
        }
        
    }, [dpeSeleted]);

    const handleClose = () => {
        setOpen(false);
        setDPEDiscovery(dpeInit)
        setValidDPE(false)
        setError(false)
    };

    const isDPEExisted = async (dpe_name, project_id) => {
        try {
            let options = {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    "project_id": project_id,
                    "dpe_name": dpe_name
                })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/check-duplicate-dpe`, options);
            if (response.ok) {
                const dpe = await response.json()
                return dpe.isDuplicate
            }
            else throw response
        }
        catch (error) {
            setIsDuplicate(false)
            setDPEDiscovery(dpeInit)
            setValidDPE(false)
            setErrorMsg(error.message)
            setError(true)
        }
    }

    const handleHostEnterValue = (e) => {
        setHost(e.target.value)
    }

    const handleValidateDPEHost = async (e) => {
        if (host !== "") {
            try {
                setIsLoading(true)
                setError(false)
                const dpe_url = `https://${host}/.well-known/dpe-configuration`
                const res = await fetch(dpe_url)
                if (res.ok) {
                    const dpe = await res.json()
                    const isDuplicated = await isDPEExisted(dpe.resources_name, project.project.project_id)
                    setIsDuplicate(isDuplicated)
                    if (isDuplicated) {
                        setError(true)
                        setErrorMsg(`DPE '${dpe.resources_name}' is existed`)
                    }
                    else setError(false)

                    setDPEDiscovery({ url: dpe_url, conf: dpe })
                    setValidDPE(true)
                    setIsLoading(false)
                }
                else {
                    throw res
                }
            }
            catch (error) {
                setIsDuplicate(false)
                setDPEDiscovery(dpeInit)
                setValidDPE(false)
                setErrorMsg(error.message)
                setError(true)
                setIsLoading(false)
            }
        }
        else {
            setError(true)
            setErrorMsg("Please fill DPE host.")
        }
    }

    const handleEditDPE = async (e) => {
        setValidDPE(false)
        try {
            let options = {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    "dpe_discovery_url": dpeDiscovery.url,
                    "dpe_name": dpeDiscovery.conf.resources_name
                })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/dpe/update-dpe/${dpeSeleted.id}`, options);
            if (response.ok) {
                handleClose()
                setIsDPEUpdate(true)
            }
            else throw response
        }
        catch (error) {
            setErrorMsg(error.message)
            setError(true)
        }
    }

    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="add-dpe-title"
                open={open}
                maxWidth='md'
            >
                <DialogTitle className='text-white' sx={{ m: 0, py: '12px', px: '20px', backgroundColor: theme.palette.primary.main }} id="add-dpe-title">
                    Edit Data Proxy Engine
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.white.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography variant='inherit' className='py-4'>Please, enter DPE host.</Typography>
                    <Box className='p-2 flex flex-row items-center gap-3 rounded border-gray-400 border'>
                        <Typography variant='subtitle1'>https://</Typography>
                        <TextField id="standard-basic" size='small' placeholder="Enter DPE host" variant="standard" defaultValue={host} onChange={handleHostEnterValue} />
                        <Typography variant='subtitle1'>/.well-known/dpe-configuration</Typography>
                        <Button variant='contained' size='small' onClick={handleValidateDPEHost}>connect</Button>
                    </Box>
                    {isLoading ? (
                        <div className='flex justify-center my-4'>
                            <CircularProgress size={20} />
                        </div>
                    ) : (
                        <Divider sx={{ my: 4 }}>
                            <Chip label="DPE Info." size="small" />
                        </Divider>
                    )

                    }

                    <Stack spacing={2} sx={{ mb: 2 }}>
                        <div className="grid grid-cols-6 gap-4">
                            <Typography variant='inherit' className="col-start-1 col-span-2">Name:</Typography>
                            <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resources_name ? dpeDiscovery.conf?.resources_name : "-"}</Typography>
                        </div>
                        <div className="grid grid-cols-6 gap-4">
                            <Typography variant='inherit' className="col-start-1 col-span-2">URL:</Typography>
                            <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.issuer ? dpeDiscovery.conf?.issuer : "-"}</Typography>
                        </div>
                        <div className="grid grid-cols-6 gap-4">
                            <Typography variant='inherit' className="col-start-1 col-span-2">Resource Type:</Typography>
                            <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resource_type ? dpeDiscovery.conf?.resource_type : "-"}</Typography>
                        </div>
                        <div className="grid grid-cols-6 gap-4">
                            <Typography variant='inherit' className="col-start-1 col-span-2">Resource Status:</Typography>
                            <Typography className="col-start-3 col-span-7">{dpeDiscovery.conf.resource_connection ? dpeDiscovery.conf?.resource_connection?.status : "-"}</Typography>
                        </div>
                    </Stack>

                </DialogContent>
                <DialogActions >
                    <div className='flex justify-between w-full items-center px-4'>
                        <div>{error && <div className='flex justify-between gap-2 text-red-800'><WarningIcon /><Typography variant='body2'>{errMsg}</Typography></div>}</div>
                        <div className='py-1'>
                            <Button disabled={!validDPE || error || isDuplicate} variant='contained' size='medium' color='primary' onClick={handleEditDPE}>
                                Edit
                            </Button>
                        </div>
                    </div>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}