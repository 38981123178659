import React from 'react'
import { Handle, Position } from '@xyflow/react';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomTooltip } from '../../../../components/TooltipCustomStyle';
import ErrorIcon from '@mui/icons-material/Error';
import TooltipStatusTitle from '../../../../components/TooltipStatusTitle';

const TestConjunctionNode = ({ data, selected }) => {

    const theme = useTheme();

    return (
        <div style={{
            padding: '10px 15px',
            backgroundColor: '#fff',
            border: selected ? `1px dashed ${theme.palette.primary.main}` : `1px dashed ${theme.palette.grey[500]}`,
            borderRadius: '5px',
            display: 'inline-block',
            textAlign: 'center',
        }}
        >
            <div>
                <Typography
                    variant='subtitle2'
                    fontSize={{
                        lg: 12,
                        md: 10,
                        sm: 10,
                        xs: 10
                    }}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'center',
                    }}
                >
                    {data.general_properties.label}
                </Typography>
            </div>
            {data?.warning === true &&
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '75%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000,
                        textAlign: 'center',
                    }}
                >
                    <CustomTooltip title={<TooltipStatusTitle headerTitle="Warning" listDetails={data?.warning_text} />} arrow placement="top" tooltipcolorstyle={theme.palette.warning.main}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                background: '#fff',
                                width: 15,
                                height: 15,
                                marginTop: '5px'
                            }}
                        >
                            <ErrorIcon sx={{
                                color: theme.palette.warning.main,
                                fontSize: 18,
                            }} />
                        </Box>
                    </CustomTooltip>
                </div>
            }
            {(data?.error === true && data?.warning === false) &&
                <div
                    style={{
                        position: 'absolute',
                        top: '-15px',
                        left: '85%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000,
                        textAlign: 'center',
                    }}
                >
                    <CustomTooltip title={<TooltipStatusTitle headerTitle="Error" listDetails={data?.error_text} />} arrow placement="top" tooltipcolorstyle={theme.palette.error.main}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                background: '#fff',
                                width: 15,
                                height: 15,
                                marginTop: '5px'
                            }}
                        >
                            <ErrorIcon sx={{
                                color: theme.palette.error.main,
                                fontSize: 18,
                            }} />
                        </Box>
                    </CustomTooltip>
                </div>
            }
            <Handle
                id="top-target"
                type="target"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="top-source"
                type="source"
                position={Position.Top}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-target"
                type="target"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="bottom-source"
                type="source"
                position={Position.Bottom}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-target"
                type="target"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="left-source"
                type="source"
                position={Position.Left}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-target"
                type="target"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
            <Handle
                id="right-source"
                type="source"
                position={Position.Right}
                style={data.showHandle ? {
                    background: 'white',
                    border: '1px solid #1976d2',
                } : {
                    background: selected ? 'white' : 'transparent',
                    border: selected ? '1px solid #1976d2' : 'none',
                }}
            />
        </div>
    );
};

export default TestConjunctionNode;